import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";
import SearchSelect, { SearchSelectProps } from "../BaseInputs/SearchSelect";

export interface FormSearchSelectProps extends Omit<SearchSelectProps, "onChange"> {
  name: string;
  required?: boolean;
  rules?: ControllerProps["rules"];
}

export default function FormSearchSelect(props: FormSearchSelectProps) {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        console.log("search select error", error)
        return (
          <SearchSelect
            onChange={onChange}
            value={field.value ?? ""}
            {...props} 
            error={error ? `This field is required` : ""}
          />
        );
      }}
      name={props.name ?? ""}
      rules={{ ...props.rules, required: props.required }}
    />
  );
}
