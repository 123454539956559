import {
  Card,
  FormText,
  FormTextInput,
  // Input,
  LoadingDots,
  Modal,
  SearchSelect,
  // ModalProps,
} from "../../../GlobalComponents";
import styled from "@emotion/styled";
import { Form, useForm, FormProvider } from "react-hook-form";
import { useContext, useState } from "react";
import { useDebounce } from "use-debounce";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { COLORS } from "../../../theme";
import { BeatLoader } from "react-spinners";
import { Button } from "@mui/material";

import {
  getOfficeLocations,
  Office,
  patchUserInfo,
  PatchUserInfoBody,
} from "./api";
import { AuthContext } from "../../../Auth";

const getManagers = async (searchTerm: string) => {
  const res = await axios.get("/mrelay_v2/options/active_user/" + searchTerm);
  return res.data;
};

interface ManagerData {
  email: string;
  name: string;
  first_name: string;
  last_name: string;
}

type ManagerModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ManagerModal = ({ open, setOpen }: ManagerModalProps) => {
  const { user } = useContext(AuthContext);
  const methods = useForm();
  const [debounceSearchTerm] = useDebounce(methods.watch("search_term"), 800);
  const [selectedManager, setSelectedManager] = useState<
    ManagerData | undefined
  >();
  const [submitError, setSubmitError] = useState("");
  const {
    data: searchResult,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["search-managers", debounceSearchTerm],
    queryFn: () => getManagers(debounceSearchTerm.toLowerCase()),
    enabled: !!debounceSearchTerm,
  });

  const queryClient = useQueryClient();
  const {
    mutate: patchUpdatedUserInfo,
    isPending: patchLoading,
    isSuccess: patchSuccess,
    error: patchError,
  } = useMutation({
    mutationFn: (body: any) => patchUserInfo(user?.azure_id, body),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["get-user"] }),
  });

  const handleSubmit = () => {
    if (selectedManager) {
      const body = {
        team_leader_email: selectedManager.email,
        team_leader_first_name: selectedManager.first_name,
        team_leader_last_name: selectedManager.last_name,
      } as PatchUserInfoBody;

      patchUpdatedUserInfo(body);
      return;
    }

    setSubmitError("No Team Leader or Manager selected");
  };

  return (
    <Modal open={open} onClose={() => setOpen && setOpen(false)}>
      <FormProvider {...methods}>
        <Wrapper>
          {patchLoading ? (
            <LoadingDots text="Updating" />
          ) : patchSuccess ? (
            <>
              <FormText variant="label" style={{ textAlign: "center" }}>
                Update Successful!
              </FormText>
              <Button onClick={() => setOpen && setOpen(false)}>Done</Button>
            </>
          ) : patchError ? (
            <>
              <FormText variant="label" style={{ textAlign: "center" }}>
                Something went wrong
              </FormText>
              <FormText style={{ textAlign: "center" }}>
                {/*@ts-ignore */}
                {patchError?.response?.data?.message ?? //@ts-ignore
                  patchError?.message ??
                  patchError}
              </FormText>
              <Button onClick={() => setOpen && setOpen(false)}>Close</Button>
            </>
          ) : (
            <>
              <FormText variant="h2" style={{ textAlign: "center" }}>
                Complete your information
              </FormText>
              {selectedManager ? (
                <div style={{ alignItems: "center" }}>
                  <FormText>You have selected</FormText>
                  <FormText variant="label">{selectedManager.name}</FormText>
                  <FormText>as your Team Lead or Manager</FormText>
                </div>
              ) : (
                <FormText style={{ textAlign: "center" }}>
                  {
                    "Please select the email address\nof your Team Lead or Manager\n"
                  }
                </FormText>
              )}
              <FormTextInput name="search_term" title="Search by Email" />
              <Card style={{ height: "40%" }}>
                <SearchResultsWrapper>
                  <div style={{ alignItems: "center" }}>
                    {isFetching ? (
                      <LoadingDots text="Searching" />
                    ) : !debounceSearchTerm ? (
                      <FormText style={{ color: COLORS.GREY, padding: 24 }}>
                        Search an email address
                      </FormText>
                    ) : !searchResult?.length ? (
                      <FormText style={{ color: COLORS.GREY, padding: 24 }}>
                        No matches for {debounceSearchTerm}
                      </FormText>
                    ) : null}
                  </div>
                  {!isFetching &&
                    searchResult?.map((data: ManagerData, i: number) => (
                      <ItemTouchableOpacity
                        key={data.email + i}
                        style={{
                          backgroundColor:
                            selectedManager === data
                              ? COLORS.TERTIARY
                              : i % 2
                              ? "#fff"
                              : "#f7f7f7",
                        }}
                        onClick={() => {
                          setSubmitError("");
                          setSelectedManager((prev) => {
                            if (prev !== data) return data;
                          });
                        }}
                      >
                        <FormText>{data.email}</FormText>
                        <FormText style={{ color: COLORS.GREY }}>
                          {data.name ?? "--"}
                        </FormText>
                      </ItemTouchableOpacity>
                    ))}
                </SearchResultsWrapper>
              </Card>
              <FormText
                style={{ color: COLORS.RED, textAlign: "center", fontSize: 12 }}
              >
                {submitError}
              </FormText>
              <Button
                // color={selectedManager ? "green" : "grey"}
                onClick={handleSubmit}
              >
                {selectedManager ? "Update Team Lead" : "Select your team lead"}
              </Button>
            </>
          )}
        </Wrapper>
      </FormProvider>
    </Modal>
  );
};

export default ManagerModal;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
  width: 100%;
`;

const SearchResultsWrapper = styled.div`
  background-color: #f7f7f7;
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 200px;
  border: 1px solid #e0e0e0;
  width: 350px;
  justify-content: center;
  align-items: center;
`;

const ItemTouchableOpacity = styled.div`
  width: 100%;
  padding: 3px;
`;

