import React from "react";
import { ReactComponent as CanvassingMap } from "../../assets/Nav/CanvassingMap.svg";
import { ReactComponent as LeadsList } from "../../assets/Nav/LeadsList.svg";
import { ReactComponent as ViewPay } from "../../assets/Nav/ViewPay.svg";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
import { useNavigate } from "react-router-dom";

export default function Links() {
  const navigate = useNavigate();

  // const onViewInvoice = () => {
  //   navigate(
  //     `/invoice/opp/${invoice.oppnum}/invoice/${invoice.invoice_number}`
  //   );
  //   setOpen(false);
  // };
  return (
    <LinksWrapper>
      <Link
        label="Canvassing Map"
        Icon={CanvassingMap}
        onClick={() => navigate("/map")}
      />
      <Link
        label="View Leads"
        Icon={LeadsList}
        onClick={() => navigate("/leads")}
      />
      {/* <Link
          label="View Pay Coming Soon"
          Icon={ViewPay}
          onClick={() => null}
        /> */}
    </LinksWrapper>
  );
}

interface LinkProps {
  label: string;
  Icon: React.ComponentType<any>;
  iconSize?: number;
  onClick: () => void;
  style?: React.CSSProperties;
}

const Link = ({ Icon, label, onClick, style, iconSize = 80 }: LinkProps) => {
  //   const iconSize = Dimensions.get("window").width > 700 ? 92 : 80;

  return (
    <LinkButtonWrapper style={style} onClick={onClick}>
      {Icon && <Icon width={iconSize} height={iconSize} />}
      <LinkLabel style={{ fontSize: iconSize === 80 ? 12 : 16 }}>
        {label}
      </LinkLabel>
    </LinkButtonWrapper>
  );
};

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 16px;
`;

const LinkLabel = styled.p`
  color: ${COLORS.WHITE};
  margin-top: 4px;
  text-align: center;
  width: 80px;
`;

const LinkButtonWrapper = styled.div`
  align-items: center;
`;
