import { useEffect, useState } from "react";
import { onlineManager } from "@tanstack/react-query";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

export default function useNetworkStatus() {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => {
    onlineManager.setOnline(true);
    setStatus(true)
  };
  const setOffline = () => {
    onlineManager.setOnline(false);
    setStatus(false)
  };

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return { isOnline: status };
}
