import { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

type SearchSelectProps = {
    value?: string;
    onChange: (value: string) => void;
    options: string[];
    error?: string;
    placeholder?: string;
};

export default function SearchSelect({ onChange, options, error, placeholder="Search an Option" }: SearchSelectProps) {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return options;
    return options.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, options]);
  
  return (
    <div style={{ position: "relative" }}>
      <TextField
        onFocus={() => setOpen(true)}
        onChange={(e: any) => setSearchTerm(e.target.value)}
        value={open ? searchTerm : value}
        placeholder={placeholder}
        sx={{ width: "100%" }}
        helperText={error}
        error={!!error}
      />
      {open && (
        <ResultsWrapper>
          {!filteredOptions?.length && (
            <Result>No results found</Result>
          )}
          {filteredOptions?.map((option: string, index: number) => {
            return (
              <Result
                key={index}
                onClick={() => {
                  onChange(option);
                  setSearchTerm(option);
                  setValue(option);
                  setOpen(false);
                }}
              >
                {option}
              </Result>
            );
          })}
        </ResultsWrapper>
      )}
    </div>
  );
}

export {
    SearchSelectProps,
}

const ResultsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  text-align: left;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const Result = styled.div`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #f2f2f2;
  &:hover {
    background-color: #f2f2f2;
  }
`;