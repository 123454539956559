import { QueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

const GOOGLE_GEOCODE_KEY = "AIzaSyALq_pM-nFcf_1NotnMDnMWfTAmCHd1saw";

const useGetGoogleAddressQuery = (
  coords: google.maps.LatLng | google.maps.LatLngLiteral | undefined,
  options: QueryOptions = {}
): UseQueryResult => {
  return useQuery({
    ...options,
    queryKey: ["get-coords-to-address", coords],
    queryFn: async () => {
      const url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        coords?.lat +
        "," +
        coords?.lng +
        "&key=" +
        GOOGLE_GEOCODE_KEY;

      const response = await fetch(url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    },
    enabled: !!coords,
  });
};

export default useGetGoogleAddressQuery;
