import React, { useState } from "react";
import styled from "@emotion/styled";
import { useDebounce } from "use-debounce";
import { TextField } from "@mui/material";

type SearchSelectProps = {
    data: string[];
    value?: string;
    onChange: (value: string) => void;
};

export default function SearchSelect({data, value, onChange} : SearchSelectProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [open, setOpen] = useState(false);

  const results: string[] = (() => {
    if (!debouncedSearchTerm) return data;
    return data.filter((el: string) => {
      return el.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  })();

  return (
    <div style={{ position: "relative" }}>
      <TextField
        onFocus={() => setOpen(true)}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={open ? searchTerm : value ?? ""}
        placeholder="Search for Utility Companies"
        sx={{ width: "100%" }}
      />
      {open && (
        <ResultsWrapper>
          {!results?.length && (
            <Result>No results found</Result>
          )}
          {results?.map((option, index) => {
            return (
              <Result
                key={index}
                onClick={() => {
                  onChange(option);
                  setSearchTerm(option);
                  setOpen(!open);
                }}
              >
                {option}
              </Result>
            );
          })}
        </ResultsWrapper>
      )}
    </div>
  );
}

const ResultsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  text-align: left;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const Result = styled.div`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #f2f2f2;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export {
    SearchSelect,
    SearchSelectProps
}