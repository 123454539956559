import React, { ReactNode, useContext } from "react";
import { FormText } from "../../../GlobalComponents";
import styled from "@emotion/styled";
import { AuthContext } from "../../../Auth";

import { ReactComponent as CT } from "../../../assets/stateIcons/CT.svg";
import { ReactComponent as FL } from "../../../assets/stateIcons/FL.svg";
import { ReactComponent as GA } from "../../../assets/stateIcons/GA.svg";
import { ReactComponent as MA } from "../../../assets/stateIcons/MA.svg";
import { ReactComponent as NJ } from "../../../assets/stateIcons/NJ.svg";
import { ReactComponent as NY } from "../../../assets/stateIcons/NY.svg";
import { ReactComponent as RI } from "../../../assets/stateIcons/RI.svg";
import { ReactComponent as TX } from "../../../assets/stateIcons/TX.svg";

const icons: { [key: string]: ReactNode } = {
  CT: <CT />,
  FL: <FL />,
  GA: <GA />,
  MA: <MA />,
  NJ: <NJ />,
  NY: <NY />,
  RI: <RI />,
  TX: <TX />,
};

const StateWidget = () => {
  const { user } = useContext(AuthContext);
  const { office } = user ?? {};

  return (
    <Wrapper style={{ alignItems: "center" }}>
      <IconWrapper
        style={office?.state === "RI" ? { maxHeight: 82, width: 82 } : {}}
      >
        {office?.state ? icons[office?.state] : null}
      </IconWrapper>
      <FormText
        variant="label"
        style={{
          color: "white",
          fontSize: 12,
          marginTop: office?.state === "RI" ? 0 : -8,
        }}
      >
        {office?.name} - {office?.state}
      </FormText>
    </Wrapper>
  );
};

export default StateWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  max-height: 82px;
  width: 82px;
`;
