import React, { useEffect, useRef, useState } from "react";

function useWatchUserPosition() {
  const positionSubscription = useRef<undefined | number>();
  const [position, setPosition] = useState<GeolocationPosition | undefined>();
  const [watching, setWatching] = useState(false);

  function startWatchingPosition(onSuccess?: Function) {
    navigator.geolocation.getCurrentPosition((pos) => {
      setPosition(pos);
      onSuccess && onSuccess(pos);
    });
    positionSubscription.current = navigator.geolocation.watchPosition(
      (pos) => {
        setWatching(true);
        setPosition(pos);
      }
    );
  }

  useEffect(() => {
    return () => {
      if (positionSubscription.current)
        navigator.geolocation.clearWatch(positionSubscription.current);
    };
  }, []);

  return {
    position,
    watching,
    startWatchingPosition,
  };
}

export default useWatchUserPosition;
