import React, { useRef, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Checkbox,
  Collapse,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import styled from "@emotion/styled";
import { LEAD_TYPE_FILTER_OPTIONS } from "../../util/optionSets";
import { COLORS } from "../../theme";

const colors: { [key: string]: string } = {
  ALL: "black",
  INTERACTIVE: COLORS.PRIMARY,
  NONINTERACTIVE: COLORS.YELLOW,
};

function FilterButton({
  filter,
  setFilter,
}: {
  filter: { label: string; value: string };
  setFilter: Function;
}) {
  const [open, setOpen] = useState(false);
  //@ts-ignore
  const wrapperRef = useRef(null);

  return (
    <Wrapper ref={wrapperRef}>
      <Button
        onClick={() => {
          console.log("pressed");
          setOpen((prev) => !prev);
        }}
      >
        <FilterListIcon />
      </Button>

      <Popover
        anchorEl={wrapperRef.current}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {LEAD_TYPE_FILTER_OPTIONS.map(
          (opt: { label: string; value: string }) => {
            const color = colors[opt.value];
            return (
              <MenuItem
                onClick={() => {
                  setOpen(false);
                  setFilter(opt);
                }}
              >
                <Checkbox
                  checked={opt.value === filter.value}
                  sx={{
                    color,
                    "&.Mui-checked": {
                      color,
                    },
                  }}
                />
                <ListItemText>{opt.label}</ListItemText>
              </MenuItem>
            );
          }
        )}
        <MenuItem
          onClick={() => {
            setOpen(false);
            setFilter({ label: "Installs", value: "INSTALLS" });
          }}
        >
          <Checkbox
            checked={"INSTALLS" === filter.value}
            sx={{
              color: COLORS.GREEN,
              "&.Mui-checked": {
                color: COLORS.GREEN,
              },
            }}
          />
          <ListItemText>Installs</ListItemText>
        </MenuItem>
      </Popover>
    </Wrapper>
  );
}

export default FilterButton;

const Wrapper = styled.div`
  position: relative;
`;
