import React, { useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";

// import SplashArt from "./SplashArt";
import { AuthContext } from "../AuthProvider";

import { ReactComponent as FooterLogo } from "../../assets/Login/FooterLogo.svg";
import { ReactComponent as MRelayLogo } from "../../assets/Login/mRelayLogo.svg";
import { Button } from "@mui/material";
import { COLORS } from "~theme";
import Context from "../../Context";
import styled from "@emotion/styled";
import { LoadingDots } from "../../GlobalComponents";

export default function Login() {
  const { instance, inProgress } = useMsal();
  const { setOverrideAzureId } = useContext(AuthContext);
  const { setDemoMode } = useContext(Context);

  const onLogin = () => {
    return instance.loginRedirect();
  };

  return (
    <Gradient>
      <MRelayLogo style={{ maxWidth: "400px", margin: "1em" }} />

      <Button
        variant="outlined"
        sx={{ backgroundColor: "#fff" }}
        onClick={onLogin}
      >
        Login
      </Button>
      <FooterWrapper>
        <FooterLogo />
        <Text
          style={{
            color: "white",
            margin: 8,
            fontSize: 11,
          }}
        >
          v{process.env.REACT_APP_VERSION}
        </Text>
      </FooterWrapper>
    </Gradient>
  );
}

const Gradient = styled.div`
  background: linear-gradient(#336699, #00b4d8);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
`;

const Text = styled.p`
  color: white;
  font-size: 18px;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
