import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getDQLeads } from "./api";
import Context from "../../Context";

function useGetInstallsQuery({ reasons }: { reasons: string[] }) {
  const { userPosition } = useContext(Context);

  const query = useQuery({
    queryKey: ["get-dq-leads", reasons, userPosition?.coords],
    queryFn: async () => {
      return await getDQLeads({
        location: userPosition?.coords,
        reasons,
      });
    },
  });

  return query;
}

export default useGetInstallsQuery;