import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { SetStateAction } from "react";
import { useGetGoogleAddressQuery } from "../../api";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
import { NavigateOptions } from "react-router-dom";
import { Link } from "react-router-dom";

interface NewLeadModalProps {
  coords?: google.maps.LatLng | google.maps.LatLngLiteral;
  setCoords: React.Dispatch<
    SetStateAction<google.maps.LatLng | google.maps.LatLngLiteral | undefined>
  >;
}

function NewLeadModal({ coords, setCoords }: NewLeadModalProps) {
  console.log("new lead modal coords", coords)
  const { data, isLoading, isSuccess, error } =
    useGetGoogleAddressQuery(coords);

  //@ts-ignore
  const address = getFormattedAddress(data?.results[0]);
  const params = (address ? { address, coords } : {}) as NavigateOptions;

  return (
    <Dialog open={!!coords} onClose={() => setCoords(undefined)}>
      <DialogTitle sx={{ textAlign: "center" }}>Create New lead at</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <AddressContainer>
          {address ? (
            <div>
              <AddressText>{address.address_line1}</AddressText>
              <AddressText>
                {address.city} {address.state} {address.zip_code}
              </AddressText>
            </div>
          ) : isLoading ? (
            <MessageText>Loading address...</MessageText>
          ) : (
            <>
              <MessageText>Address could not be found.</MessageText>
              {error && <MessageText>{error?.message}</MessageText>}
              <MessageText>Please enter manually.</MessageText>
            </>
          )}
          {address && (
            <Button
              component={Link}
              to={"/lead"}
              state={params}
              variant="outlined"
            >
              Use This Address
            </Button>
          )}
          <Button
            component={Link}
            to={"/lead"}
            state={{ address: null, coords: null }}
            variant="outlined"
          >
            Enter Address Manually
          </Button>
        </AddressContainer>
      </DialogContent>
    </Dialog>
  );
}

const KEYS = [
  "street_number",
  "route",
  "locality",
  "administrative_area_level_1",
  "postal_code",
];
function getFormattedAddress(googleGeoResult: any) {
  if (!googleGeoResult?.address_components) return null;

  const cleaned = googleGeoResult?.address_components.reduce(
    //@ts-ignore
    (result, { long_name, short_name, types }) => {
      const key = KEYS.find((key) => types.includes(key));
      if (key) result[key] = { long_name, short_name };
      return result;
    },
    {}
  );
  return {
    state: cleaned?.administrative_area_level_1?.short_name,
    zip_code: cleaned?.postal_code?.long_name,
    city: cleaned?.locality?.long_name,
    address_line1: `${cleaned?.street_number?.long_name} ${cleaned?.route?.long_name}`,
  };
}

export default NewLeadModal;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MessageText = styled(Typography)`
  color: ${COLORS.GREY};
  margin: 6px 0px;
  text-align: center;
`;

const AddressText = styled(Typography)`
  font-size: 16;
  text-align: center;
  color: ${COLORS.SECONDARY};
  margin: 3px;
`;
