import React, { useState, useEffect, useContext } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { PageWrapper, NetworkStatusOverlay } from "../../GlobalComponents";
import { AuthContext } from "../../Auth";
import { useStoreFailedLeads, useGetInstallsQuery } from "../../Hooks";
import NewLeadModal from "./NewLeadModal";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { FetchLeadsResponse, getLeads } from "../../api";
import { LEAD_TYPE_FILTER_OPTIONS } from "../../util/optionSets";
import styled from "@emotion/styled";
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as CenterIcon } from "../../assets/Map/CenterIcon.svg";
import UserIcon from "../../assets/Map/UserLocationMarker.svg";
import CustomMarker, { InstallMaker } from "./CustomMarker";
import LeadInfoWindow from "./LeadInfoWindow";
import FilterButton from "./FilterButton";
import Context from "../../Context";
import ViewLeadDialog from "../ViewLeads/ViewLeadDialog";
import { getDQReasons } from "./api";
import DQLeadsModal from "./DQLeadsModal";
import { useGetDQsQuery } from "../../Hooks";
import LegendModal from "./LegendModal";

const center = {
  lat: 40.559103613894166,
  lng: -74.43146696046337,
};

// react-google-maps/api docs https://www.npmjs.com/package/@react-google-maps/api
const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8",
  });
  const { removeLeadIfStored, leads: cachedLeads } = useStoreFailedLeads();
  const { user } = React.useContext(AuthContext);
  const { userPosition } = useContext(Context);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [filter, setFilter] = useState(LEAD_TYPE_FILTER_OPTIONS[0]);
  const [selectedMarker, setSelectedMarker] = useState<{
    element: any;
    lead: Lead;
  } | null>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [dqModalOpen, setDQModalOpen] = useState(false);
  const [selectedDQs, setSelectedDQs] = useState<string[]>([]);
  const [legendModalOpen, setLegendModalOpen] = useState(false);

  const [newLeadCoords, setNewLeadCoords] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >();

  //@ts-ignore
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["get-leads", user?.email, filter.value],
    queryFn: async ({ pageParam }) => {
      return await getLeads({
        email: user?.email ?? "",
        filter: filter.value,
        next: pageParam,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: FetchLeadsResponse) =>
      lastPage?.next ?? undefined,
  });

  const { data: dqOptions, error: dqOptionsError } = useQuery({
    queryKey: ["get-dq-reasons"],
    queryFn: async () => {
      return await getDQReasons();
    },
  });

  const { data: dqLeads, error: dqLeadsError } = useGetDQsQuery({
    reasons: selectedDQs,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data]);

  //@ts-ignore
  const leads = data?.pages?.map((page) => page?.values ?? []).flat();

  const { data: installs } = useGetInstallsQuery();

  useEffect(() => {
    if (leads) leads?.forEach((lead) => removeLeadIfStored(lead));
  }, [leads]);

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const onUnmount = (map: google.maps.Map) => {
    setMap(null);
  };

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (selectedMarker) return setSelectedMarker(null);
    const coords = {
      lat: e.latLng?.lat(),
      lng: e.latLng?.lng(),
    } as google.maps.LatLngLiteral;
    coords.lat && coords.lng && setNewLeadCoords(coords);
  };

  const goToUserPosition = () => {
    if (userPosition?.coords?.latitude && userPosition?.coords?.longitude)
      map?.setCenter({
        lat: userPosition.coords.latitude,
        lng: userPosition.coords.longitude,
      });
  };

  const renderMarkers = (leadsToRender: Lead[], hasBeenUploaded = true) => {
    if (filter.value === "INSTALLS") {
      return installs.map((install: Install, i: number) => {
        return <InstallMaker install={install} key={"install" + i} />;
      });
    }

    return leadsToRender?.map((lead, i) => (
      <CustomMarker
        lead={lead}
        setSelectedMarker={setSelectedMarker}
        key={"lead" + i}
        hasBeenUploaded={hasBeenUploaded}
      />
    ));
  };

  if (!isLoaded) return null;

  return (
    <PageWrapper>
      <ViewLeadDialog
        lead={selectedLead}
        onClose={() => {
          setSelectedMarker(null);
          setSelectedLead(null);
        }}
      />
      <GoogleMap
        mapContainerStyle={{
          height: window.innerHeight - 80,
        }}
        center={
          userPosition?.coords.latitude
            ? {
                lat: userPosition.coords.latitude,
                lng: userPosition.coords.longitude,
              }
            : center
        }
        zoom={20}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          tilt: 0,
          gestureHandling: "greedy",
        }}
        onClick={onMapClick}
      >
        <NetworkStatusOverlay style={{ right: 60, top: 10 }} />
        {leads && renderMarkers(leads)}
        {cachedLeads && renderMarkers(cachedLeads, false)}
        {dqLeads && renderMarkers(dqLeads)}
        {/*@ts-ignore */}
        {selectedMarker && (
          <LeadInfoWindow
            lead={selectedMarker?.lead}
            setSelectedLead={() => setSelectedLead(selectedMarker?.lead)}
            onClose={() => setSelectedMarker(null)}
          />
        )}
        {!!userPosition?.coords && (
          <MarkerF
            position={{
              lat: userPosition.coords.latitude,
              lng: userPosition.coords.longitude,
            }}
            icon={UserIcon}
          />
        )}

        {<NewLeadModal coords={newLeadCoords} setCoords={setNewLeadCoords} />}
        {map && (
          <ControlsWrapper>
            <ToolButton onClick={() => goToUserPosition()}>
              <CenterIcon />
            </ToolButton>
            <ToolButton onClick={() => goToUserPosition()}>
              <FilterButton filter={filter} setFilter={setFilter} />
            </ToolButton>
            <ToolButton onClick={() => setDQModalOpen(true)}>
              <div style={{fontWeight: "bold"}}>DQ</div>
            </ToolButton>
            <ToolButton onClick={() => setLegendModalOpen(true)}>
              <InfoIcon />
            </ToolButton>
          </ControlsWrapper>
        )}
      </GoogleMap>
      <DQLeadsModal
        open={dqModalOpen}
        setOpen={setDQModalOpen}
        dqOptions={dqOptions}
        selectedDQs={selectedDQs}
        onClose={(selectedReasons) => {
          setSelectedDQs(
            Object.keys(selectedReasons).filter(
              (reason) => selectedReasons[reason]
            )
          );
        }}
      />
      <LegendModal open={legendModalOpen} setOpen={setLegendModalOpen} />
    </PageWrapper>
  );
};

export default React.memo(Map);

const ControlsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
`;

const ToolButton = styled.button`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  aspect-ratio: 1;
  height: 40px;
  width: 40px;
  max-width: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
`;
