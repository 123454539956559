import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  useFormContext,
  Controller,
  FieldValues,
} from "react-hook-form";
import { useDebounce } from "use-debounce";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";

const NAME = "utility_company";
interface UtilityCompany {
  "@odata.etag": string;
  iis_name: string;
  iis_utilityid: string;
  mts_state: string;
  mts_zipcode: string;
}

const getUtilityCompanies = async () => {
  const { data } = await axios.get(
    "https://5n3y0659e4.execute-api.us-east-1.amazonaws.com/prod/sales/v4/get_utils"
  );
  return data.map((d: UtilityCompany) => d.iis_name);
};

export default function UtilitySearch() {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const { control } = useFormContext<FieldValues>();

  const { data: allUtilityCompanies } = useQuery({
    queryKey: ["get-utility-companies"],
    queryFn: getUtilityCompanies,
    initialData: [],
  });

  const utilityCompanies: string[] = (() => {
    if (!debouncedSearchTerm) return allUtilityCompanies;
    return allUtilityCompanies.filter((el: string) => {
      return el.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
    });
  })();

  return (
    <div>
      <Controller
        control={control}
        // rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div style={{ position: "relative" }}>
              {error && <p style={{ color: "red" }}>Utility Company is required</p>}
              <TextField
                onFocus={() => setOpen(true)}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={open ? searchTerm : value ?? ""}
                placeholder="Search for Utility Companies"
                sx={{ width: "100%" }}
              />
              {open && (
                <UtilResultsWrapper>
                  {!utilityCompanies?.length && (
                    <UtilResult>No results found</UtilResult>
                  )}
                  {utilityCompanies?.map((option, index) => {
                    return (
                      <UtilResult
                        key={index}
                        onClick={() => {
                          onChange(option);
                          setSearchTerm(option);
                          setOpen(!open);
                        }}
                      >
                        {option}
                      </UtilResult>
                    );
                  })}
                </UtilResultsWrapper>
              )}
            </div>
          );
        }}
        name={NAME}
      />
    </div>
  );
};


const UtilResultsWrapper = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #000;
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    text-align: left;
    &:hover {
        background-color: #f2f2f2;
    }

`;

const UtilResult = styled.div`
    padding: 12px;
    font-size: 14px;
    border-bottom: 1px solid #f2f2f2;
    &:hover {
        background-color: #f2f2f2;
    }
`;