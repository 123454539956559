import axios from "axios";
import { parseLead } from "../util";

export interface FetchLeadsResponse {
  values: Lead[];
  next?: string;
}

export const getLeads = async ({
  email,
  next = "",
  search,
  filter = "ALL",
}: {
  email: string;
  next?: string;
  search?: string;
  filter?: string;
}) => {
  let url = `/mrelay_v2/leads/${email}?filter=${filter.toUpperCase()}`;
  let options = { headers: {} };
  //@ts-ignore
  if (next) options.headers["x-next"] = next;
  if (search) url += `&search=${search}`;

  const res = await axios.get(url, options);

  res.data.values = res.data.values.map(parseLead);
  return res.data as FetchLeadsResponse;
};
