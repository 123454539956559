import React from "react";
import { Modal } from "../../GlobalComponents";
import { useQueryClient } from "@tanstack/react-query";
import { COLORS } from "../../theme";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

interface PostNewLeadsStatusModalProps {
  status?: string;
  error?: any;
  open: boolean;
  setOpen: (args: any) => any;
  isPaused?: boolean;
}

const PostNewLeadStatusModal = ({
  status,
  error,
  open,
  setOpen,
  isPaused,
}: PostNewLeadsStatusModalProps) => {
  const navigation = useNavigate();
  const goBack = () => {
    setOpen(false);
    navigation("/map");
  };

  return (
    <Modal open={open}>
      <Wrapper>
        {error ? (
          <>
            <Typography> Something went wrong</Typography>
            <Typography> {error?.message ?? error}</Typography>
            <Typography> {error?.response?.data?.message}</Typography>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Back to form
            </Button>
          </>
        ) : isPaused ? (
          <>
            <Typography> No Network Detected</Typography>
            <Typography>
              This lead will automatically be submitted once the app has been
              re-connected to the network
            </Typography>
            <Button variant="outlined" onClick={() => goBack()}>
              Done
            </Button>
          </>
        ) : status === "loading" ? (
          <>
            <Typography style={{ color: COLORS.GREY }}>
              Submitting new lead...
            </Typography>
          </>
        ) : status === "success" ? (
          <>
            <Typography variant="h6"> Success!</Typography>
            <Typography> Your lead has been submitted</Typography>
            <Button variant="contained" onClick={() => goBack()}>
              Back to Map
            </Button>
          </>
        ) : null}
      </Wrapper>
    </Modal>
  );
};

export default PostNewLeadStatusModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
`;
