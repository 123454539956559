import { createTheme } from "@mui/material";

const COLORS = {
  PRIMARY: "#00BFEF",
  SECONDARY: "#336699",
  TERTIARY: "#C3EBF8",
  BACKGROUND: "#FFFFFF",
  BACKGROUND2: "#25B4E8",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GREY: "#999999",
  GREY2: "#CECECE",
  LIGHTGREY: "#E5E5E5",
  DARKGREY: "#676767",
  LIGHTRED: "#FFBEBE",
  RED: "#ff4d4d",
  REDTRANSPARENT: "rgba(203, 82, 82, 0.70)",
  DARKRED: "#B00020",
  GREEN: "#5EF4A3",
  LIGHT_GREEN: "#BAFFDA",
  YELLOW: "#FFD466",
  LIGHTYELLOW: "#FFE9B1",
  GREENTRANSPARENT: "#5EF4A3322",
  DARKGREEN: "#49A55E",
};

const theme = createTheme({
  typography: {},
  palette: {
    mode: "light",
    primary: {
      main: "#336699",
    },
    secondary: {
      main: "#00ccff",
    },
  },
});

export { COLORS, theme };