import React from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../theme";

type ButtonSelectProps = {
    options: SelectOption[];
    onChange: (value: string) => void;
}

const ButtonSelect = ({
  options,
  onChange,
}: ButtonSelectProps) => {
  const [value, setValue] = React.useState<string>(options[0].value);

  return (
    <TabButtonsWrapper>
      {options.map((option: SelectOption, index: number) => (
        <TabButton
          key={index}
          onClick={() => {
            setValue(option.value);
            onChange && onChange(option.value);
          }}
          active={value === option.value}
        >
          {option.label}
        </TabButton>
      ))}
    </TabButtonsWrapper>
  );
};

export {
    ButtonSelectProps,
    ButtonSelect,
}

type TabButtonProps = {
  active: boolean;
};

const TabButton = styled.button<TabButtonProps>`
  gradient: linear-gradient(90deg, #00ccff 0%, #336699 100%);
  border: none;
  background: ${(props) =>
    props.active
      ? `linear-gradient(to top, #fff, ${COLORS.PRIMARY})`
      : "white"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  padding: 10px;
  border-radius: 5px;
  width: 50%;
`;

const TabButtonsWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
  margin: 10px 0;
`;
