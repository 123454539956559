import React, { useContext } from "react";
import { ReactComponent as Rain } from "../../../assets/Weather/Rain.svg";
import { ReactComponent as Cloudy } from "../../../assets/Weather/Cloudy.svg";
import { ReactComponent as Mix } from "../../../assets/Weather/Mix.svg";
import { ReactComponent as PartlyCloudy } from "../../../assets/Weather/PartlyCloudy.svg";
import { ReactComponent as Snow } from "../../../assets/Weather/Snow.svg";
import { ReactComponent as Sun } from "../../../assets/Weather/Sun.svg";
import { ReactComponent as Thunder } from "../../../assets/Weather/Thunder.svg";
import { ReactComponent as Wind } from "../../../assets/Weather/Wind.svg";
import { useQuery } from "@tanstack/react-query";
import Context from "../../../Context";
import { COLORS } from "../../../theme";
import styled from "@emotion/styled";

const weatherCodes = {
  0: { label: "Sunny", icon: <Sun /> },
  1: { label: "Clear", icon: <Sun /> },
  2: { label: "Partly cloudy", icon: <PartlyCloudy /> },
  3: { label: "Cloudy", icon: <Cloudy /> },
  45: { label: "Fog", icon: <Cloudy /> },
  48: { label: "Fog", icon: <Cloudy /> },
  51: { label: "Drizzle", icon: <Rain /> },
  53: { label: "Rain", icon: <Rain /> },
  55: { label: "Rain", icon: <Rain /> },
  56: { label: "Rain", icon: <Mix /> },
  57: { label: "Rain", icon: <Mix /> },
  61: { label: "Rain", icon: <Rain /> },
  63: { label: "Rain", icon: <Rain /> },
  65: { label: "Rain", icon: <Rain /> },
  66: { label: "Rain", icon: <Mix /> },
  67: { label: "Rain", icon: <Mix /> },
  71: { label: "Snow", icon: <Snow /> },
  73: { label: "Snow", icon: <Snow /> },
  75: { label: "Snow", icon: <Snow /> },
  77: { label: "Snow", icon: <Snow /> },
  80: { label: "Rain", icon: <Rain /> },
  81: { label: "Rain", icon: <Rain /> },
  82: { label: "Rain", icon: <Rain /> },
  85: { label: "Rain", icon: <Snow /> },
  86: { label: "Rain", icon: <Snow /> },
  95: { label: "Storm", icon: <Thunder /> },
  96: { label: "Hail Storm", icon: <Thunder /> },
  97: { label: "Hail Storm", icon: <Thunder /> },
};
const W = {
  //"Sunny,
  //"Mainly clear"
  //"Partly cloudy"
  //"Cloudy"
  //"Fog",
  //"Fog",
  //"Light Drizzle",
  //"Some Drizzle",
  //"Heavy Drizzle",
  //"Light Freezing Drizzle",
  //"Heavy Freezing Drizzle",
  //"Light Rain",
  //"Some Rain",
  //"Heavy Rain",
  //"Light Freezing Rain",
  //"Heavy Freezing Rain",
  //"Light Snow Fall",
  //"Some Snow Fall",
  //"Heavy Snow Fall",
  //"Snow Grains",
  //"Slight Rain Showers",
  //"Some Rain Showers",
  //"Heavy Rain Showers",
  //"Slight Snow Showers",
  //"Heavy Snow Showers",
  //"Thunderstorm",
  //"Thunderstorm with Slight Hail",
  //"Thunderstorm with Some Hail",
};

const getWeather = async (params: any) => {
  const { latitude, longitude } = params.queryKey[1].coords;
  const response = await fetch(
    `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&&current_weather=true&temperature_unit=fahrenheit&windspeed_unit=mph`
  );
  const data = await response.json();
  return data;
};

const WeatherWidget = () => {
  const { userPosition } = useContext(Context);
  console.log("POSITION", userPosition);
  const {
    data: weatherData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["weather", userPosition],
    queryFn: getWeather,
    enabled: !!userPosition,
  });
  if (!weatherData?.current_weather) return null;
  const { weathercode, temperature } = weatherData?.current_weather;
  const { label, icon: Icon } =
    weatherCodes[weathercode as keyof typeof weatherCodes] ?? {};

  return (
    <Wrapper>
      <IconWrapper>
        {Icon ?? null}
        <TempText>{Math.round(+temperature) + "\u00B0"}</TempText>
      </IconWrapper>
      <Text>{label ?? ""}</Text>
    </Wrapper>
  );
};

export default WeatherWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  position: relative;
  min-height: 36px;
  height: 36px;
`;

const Text = styled.p`
  color: ${COLORS.WHITE};
  text-align: center;
  margin-top: 4px;
`;

const TempText = styled.p`
  font-size: 24px;
  text-align: center;
  color: ${COLORS.WHITE};
`;
