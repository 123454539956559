import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as Person } from "../../assets/List/PersonBlue.svg";
import { formatPhoneNumber } from "../../util/formatters";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
function ViewLeadDialog({
  lead,
  onClose,
}: {
  lead?: Lead | null;
  onClose: Function;
}) {
  const date = dayjs(lead?.appointment_datetime).format("MMM D, YYYY h:mmA");
  const isInteractive = lead?.lead_type.value.toUpperCase() === "INTERACTIVE";
  const createdOn = dayjs(lead?.created_on).format("MMM D, YYYY h:mmA");
  return (
    <Dialog open={!!lead} onClose={() => onClose()}>
      <DialogTitle>
        <Stack direction={"row"} alignItems={"center"} gap="1em">
          <Dot
            style={{
              borderColor: isInteractive
                ? COLORS.LIGHT_GREEN
                : COLORS.LIGHTYELLOW,
              background: isInteractive ? COLORS.GREEN : COLORS.YELLOW,
            }}
          />
          <div>
            {lead?.address_line1}
            <br />
            {lead?.city} {lead?.state} {lead?.zip_code}
          </div>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap="16px">
          <Typography variant="caption">Submitted {createdOn}</Typography>
          {isInteractive && (
            <>
              <Line />
              <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
                <Person />
                <div>
                  <Typography>
                    {lead?.first_name} {lead?.last_name}
                  </Typography>
                  <Typography>
                    {lead?.first_name_2} {lead?.last_name_2}
                  </Typography>
                  <Typography>
                    {lead?.phone ? formatPhoneNumber(lead?.phone) : "--"}
                  </Typography>
                  {lead?.email && <Typography>{lead?.email}</Typography>}
                </div>
              </Stack>
              <Line />
              <div>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Appointment: </span>
                  {date !== "Invalid Date" ? date : "No Demo Date"}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Utility: </span>
                  {lead.utility_company ?? "--"}
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>
                    Monthly Util Payment:{" "}
                  </span>
                  {lead.monthly_utility_bill ?? "--"}
                </Typography>
              </div>
            </>
          )}
          <Line />
          <div>
            <Typography sx={{ fontWeight: "bold" }}>Notes:</Typography>
            <Typography>{lead?.notes ?? "--"}</Typography>
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ViewLeadDialog;

const Dot = styled.div`
  border-radius: 100px;
  aspect-ratio: 1;
  min-height: 16px;
  min-width: 16px;
  height: 24px;
  border: solid 4px;
  box-shadow: 1px 1px 4px lightgrey;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${COLORS.LIGHTGREY};
`;
