import React, { useEffect, useState, useContext, useMemo } from "react";
import styled from "@emotion/styled";
import { PageWrapper, AddressLookupModal } from "../../GlobalComponents";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import {
  FormSelect,
  FormTextInput,
  FormDateTimePicker,
  FormSwitch,
  FormButtonSelect,
  FormSearchSelect,
} from "../../GlobalComponents/FormComponents";
import { COLORS } from "../../theme";
import UtilitySearch from "./UtilitySearch";
import { useLocation } from "react-router-dom";
import { useStoreFailedLeads, useNetworkStatus } from "../../Hooks";
import { AuthContext } from "../../Auth";
import Context from "../../Context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postNewLead } from "../../api";
import { Switch } from "@mui/material";
import PostNewLeadStatusModal from "./PostNewLeadStatusModal";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LEAD_TYPE_OPTIONS, LEAD_TYPE_SUB_OPTIONS, DQ_REASONS } from "../../util/optionSets";
import DQReasons from "./DQReasons";

const TABS = ["Ineractive", "Non-Interactive"];

function combineAddress(address: any) {
  const { address_line1, city, state, zip_code } = address ?? {};
  return `${address_line1}, ${city}, ${state} ${zip_code}`;
}

export default function NewLead() {
  const { addLead: addLeadToCache } = useStoreFailedLeads();
  const { isOnline } = useNetworkStatus();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const params = location?.state ?? {};
  const {
    address: routeAddress,
    coords: routeCoords,
    leadToEdit,
    readOnly,
  } = params;
  const { user } = useContext(AuthContext);
  const { userPosition, devMode } = useContext(Context);
  const userCoords = userPosition?.coords || { latitude: 0, longitude: 0 };

  const defaultValues = {
    ...routeAddress,
    ...leadToEdit,
    appointment_datetime: leadToEdit?.appointment_datetime
    ? new Date(leadToEdit?.appointment_datetime)
    : "",
    lead_type: 'INTERACTIVE',
    lead_subtype: LEAD_TYPE_SUB_OPTIONS['INTERACTIVE'][0],
  };
  
  const methods = useForm({ defaultValues });
  const { watch } = methods;

  const goBack = () => {
    navigate("/map");
  };

  const [tab, setTab] = useState(TABS[0]);
  const [address, setAddress] = useState(routeAddress);
  const [coords, setCoords] = useState(routeCoords);
  const [editAddressOpen, setEditAddressOpen] = useState(false);
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [shouldWriteToCRM, setShouldWriteToCRM] = useState(false);

  useEffect(() => {
    console.log("COORDS CHANGED", coords);
  }, [coords]);

  useEffect(() => {
    console.log("ADDRESS CHANGED", address);
  }, [address]);

  useEffect(() => {
    if (!address) {
      setEditAddressOpen(true);
    }

    if (leadToEdit) {
      methods.reset(leadToEdit);
    }
  }, []);

  const {
    mutate: postLeadMutation,
    error,
    status,
    isPaused,
  } = useMutation({
    //@ts-ignore
    mutationFn: (body: PostNewLeadBody) => {
      postNewLead(body);
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["get-leads"] });
        queryClient.invalidateQueries({ queryKey: ["get-knocks"] });
      }, 2000);
    },
    onError: (_: any, body: PostNewLeadBody) => {
      addLeadToCache(body);
    },
  });

  const leadType = watch("lead_type");
  const leadSubType = watch("lead_subtype");
  const isInterested = useMemo(
    () => leadSubType?.value === "INTERESTED",
    [leadSubType]
  );
  const isDQ = useMemo(() => leadSubType?.value === "DQ", [leadSubType]);

  useEffect(() => {
    methods.clearErrors();
  }, [leadSubType]);

  const onSubmit = (data: any) => {
    console.log("SUBMITTING", data);
    let body = {
      address_line1: address?.address_line1,
      // address_line2: "Suite B",
      city: address.city,
      zip_code: address.zip_code,
      state: address.state,
      lead_type: leadType,
      lead_subtype: leadSubType,
      notes: data.notes,
      lead_source: "Canvassing",
      lead_latitude: coords?.lat,
      lead_longitude: coords?.lng,
      canvasser_latitude: userCoords.latitude,
      canvasser_longitude: userCoords.longitude,
      device: `web`,
      canvasser_email: user?.email,
      insert_crm_lead: shouldWriteToCRM, //TODO: important
    } as PostNewLeadBody;

    if (leadSubType === "DQ") {
      body.dq_reason = data.dq_reason;
    }

    if (leadType === "INTERACTIVE") {
      if (data?.first_name) body.first_name = data.first_name;
      if (data?.last_name) body.last_name = data.last_name;
      if (data?.phone) body.phone = data.phone.replaceAll(/\D/g, "");
      if (data?.email) body.email = data.email;
      if (data?.appointment_datetime) {
        body.appointment_datetime = data.appointment_datetime?.$d ?? "";
        body.appointment_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "";
      };
    }

    if (data.first_name_2) body.first_name_2 = data.first_name_2;
    if (data.last_name_2) body.last_name_2 = data.last_name_2;
    if (data.bill_amount) {
      body.monthly_utility_bill = data.monthly_bill
        ? data.bill_amount / 12
        : data.bill_amount;
    }
    if (data.utility_company) body.utility_company = data.utility_company;

    console.log("----- POST LEAD BODY -----");
    console.log(JSON.stringify(body, null, 2));
    console.log("--------------------------");

    // if (!isOnline) return addLeadToCache(body);
    // setResultModalOpen(true);
    // postLeadMutation(body);
  };

  return (
    <PageWrapper variant="gradient">
      <FormWrapper>
        <Header>
          <BackButton onClick={goBack}>
            <ArrowBackIcon />
          </BackButton>
          <HeaderText>New Lead</HeaderText>
          <div style={{ padding: 20 }} />
        </Header>
        <AddressText>{combineAddress(address)}</AddressText>
        <EditAddressButton onClick={() => setEditAddressOpen(true)}>
          Edit Address
        </EditAddressButton>
        <SectionTitle style={{ width: "100%" }}>Lead Details</SectionTitle>
        <FormProvider {...methods}>
          <FormButtonSelect name="lead_type" options={LEAD_TYPE_OPTIONS} />
          <FormButtonSelect
            name="lead_subtype"
            //@ts-ignore
            options={LEAD_TYPE_SUB_OPTIONS[leadType]}
          />
          {leadSubType === "DQ" && (
            <div style={{ width: "100%" }}>
              <SectionTitle>DQ Reason*</SectionTitle>
              <DQReasons />
            </div>
          )}
          <div style={{ width: "100%" }}>
            {leadType === "INTERACTIVE" && (
              <div>
                <SectionTitle>Customer Information</SectionTitle>
                <Text>Name*</Text>
                <FormTextInput
                  name="first_name"
                  label="First Name"
                  wrapperStyle={{ marginBottom: 10 }}
                  sx={{ width: "100%" }}
                  required={isInterested}
                />
                <FormTextInput
                  name="last_name"
                  label="Last Name"
                  sx={{ width: "100%" }}
                  required={isInterested}
                />
                <Text>Phone*</Text>
                <FormTextInput
                  name="phone"
                  label="Phone"
                  sx={{ width: "100%" }}
                  rules={{
                    pattern: /^\d{10}$/,
                    message: "Invalid phone number",
                  }}
                  mask="(#00) 000-0000"
                  definitions={{
                    "#": /[1-9]/,
                  }}
                  required={isInterested}
                />
                <Text>Email</Text>
                <FormTextInput
                  name="email"
                  label="Email"
                  sx={{ width: "100%" }}
                  rules={{ pattern: /^\S+@\S+$/i, message: "Invalid email" }}
                />
                <Text>Appointment Date | Time</Text>
                <FormDateTimePicker name="appointment_datetime" />
              </div>
            )}
            <SectionTitle>Utility</SectionTitle>
            <Text>Utility Provider</Text>
            <UtilitySearch />
            {watch("utility_company") && (
              <div>
                <Text>Monthly Bill</Text>
                <FormSwitch name="monthly_bill" label="Monthly Bill" />
                <Text>Utility Bill Amount</Text>
                <FormTextInput
                  name="bill_amount"
                  label="Bill Amount"
                  sx={{ width: "100%" }}
                  rules={{ pattern: /^\d+$/, message: "Invalid bill amount" }}
                  maskType="currency"
                />
              </div>
            )}
            <SectionTitle>Notes</SectionTitle>
            <FormTextInput name="notes" label="Notes" sx={{ width: "100%" }} />
            <ButtonWrapper>
              <SubmitButton
                onClick={(e) => {
                  e.preventDefault();
                  methods.handleSubmit(onSubmit, console.log)();
                }}
              >
                Submit
              </SubmitButton>
            </ButtonWrapper>
          </div>
        </FormProvider>
        {user?.support && (
          <div>
            <Text>Should write to CRM?(dev only)</Text>
            <Switch onChange={() => setShouldWriteToCRM(!shouldWriteToCRM)} />
          </div>
        )}
      </FormWrapper>
      <AddressLookupModal
        open={editAddressOpen}
        onClose={() => {
          if (address) {
            return setEditAddressOpen(false);
          }

          navigate("/map");
        }}
        onAddressConfirm={(addressData: any) => {
          console.log("address", addressData.address);
          setAddress(addressData.address);
          setCoords(addressData.coords);
          setEditAddressOpen(false);
        }}
      />
      <PostNewLeadStatusModal
        open={resultModalOpen}
        setOpen={setResultModalOpen}
        status={status}
        error={error}
        isPaused={isPaused}
      />
    </PageWrapper>
  );
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 20px 0 20px;
  border-radius: 5px;
  background-color: white;
`;

const Text = styled.p`
  font-size: 12px;
`;

const SectionTitle = styled.h2`
  text-align: left;
  color: ${COLORS.PRIMARY};
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const HeaderText = styled.h1`
  text-align: center;
  color: ${COLORS.PRIMARY};
  font-size: 22px;
`;

const AddressText = styled.p`
  color: ${COLORS.SECONDARY};
  text-align: center;
  margin-bottom: 12px;
  font-size: 18px;
  // font-family: GothamBook;
`;

const EditAddressButton = styled.button`
  background-color: #fff;
  border: 1px solid ${COLORS.GREY};
  color: ${COLORS.GREY};
  border-radius: 20px;
`;

const SubmitButton = styled.button`
  background-color: ${COLORS.GREEN};
  border: none;
  color: ${COLORS.GREY};
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

type TabButtonProps = {
  active: boolean;
};

const TabButton = styled.button<TabButtonProps>`
  gradient: linear-gradient(90deg, #00ccff 0%, #336699 100%);
  border: none;
  background: ${(props) =>
    props.active
      ? `linear-gradient(to top, #fff, ${COLORS.PRIMARY})`
      : "white"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  padding: 10px;
  border-radius: 5px;
  width: 50%;
`;

const TabButtonsWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.PRIMARY};
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #f2f2f2;
  }
`;
