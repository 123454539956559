import React, { useEffect, useState } from "react";
import { parseLead } from "../util";

interface CachedLeadsObject {
  [key: string]: PostNewLeadBody;
}

function useStoreFailedLeads() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [leadsLookup, setLeadsLookup] = useState<CachedLeadsObject>({});

  useEffect(() => {
    (async () => {
      const data = (window.localStorage.getItem("failed-lead-uploads")) ?? "{}";
      const parsed = JSON.parse(data) as CachedLeadsObject;
      setLeads(Object.values(parsed).map((lead) => parseLead(lead)));
      setLeadsLookup(parsed);
    })();
  }, []);

  const addLead = async (lead: PostNewLeadBody) => {
    const data = (window.localStorage.getItem("failed-lead-uploads")) ?? "{}";
    const parsed = JSON.parse(data);
    window.localStorage.setItem(
      "failed-lead-uploads",
      JSON.stringify({
        ...parsed,
        [lead.address_line1 + lead.city + lead.state]: lead,
      })
    );
    setLeads((prev) => [...prev, parseLead(lead)]);
  };

  const removeLeadIfStored = async (lead: Lead) => {
    const key = lead.address_line1 + lead.city + lead.state;
    if (leadsLookup[key]) {
      setLeads((prev) =>
        prev.filter(
          (lead) => lead.address_line1 + lead.city + lead.state !== key
        )
      );
      setLeadsLookup((prev) => {
        delete prev[key];
        window.localStorage.setItem("failed-lead-uploads", JSON.stringify(prev));
        return prev;
      });
    }
  };

  return { leads, addLead, removeLeadIfStored };
}

export default useStoreFailedLeads;
