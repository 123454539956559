import axios from "axios";

const getInstalls = async ({ latitude, longitude }: Coordinates) => {
  // const url = `/mrelay_v2/filters/installs?latitude=${latitude}&longitude=${longitude}`;
  //Use when there are no installs near by for testing
  const url =
    "https://api-dev.momentumsolar.io/mrelay_v2/filters/installs?latitude=40.55445565393373&longitude=-74.36988274232937";
  const res = await axios.get(url);
  return res.data as Install[];
};

export default getInstalls;
