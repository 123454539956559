import React, { useContext, useState } from "react";
import { PageWrapper, Modal } from "../../GlobalComponents";
import { AuthContext } from "../../Auth";
import Context from "../../Context";
import styled from "@emotion/styled";
import { Switch, Button } from "@mui/material";
import { COLORS } from "../../theme";
import axios from "axios";

export default function Settings() {
  const { user } = useContext(AuthContext);
  const { devMode, setDevMode, demoMode, setDemoMode, userPosition } = useContext(Context);
  const [showDevModal, setShowDevModal] = useState(false);
  console.log("VERSION", process.env.REACT_APP_VERSION)

  console.log("USER POSITION", userPosition)

  return (
    <PageWrapper variant="gradient">
      <div style={{ padding: 16, paddingTop: 32 }}>
        <Wrapper>
          <SectionWrapper>
            <p>v{process.env.REACT_APP_VERSION}</p>
          </SectionWrapper>
          {user?.support && (
            <SectionWrapper>
              <SectionTitle>Support Settings</SectionTitle>
              <SwitchWrapper>
                <Switch
                  checked={devMode}
                  onChange={(e) => setDevMode(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <div>Developer Mode</div>
              </SwitchWrapper>
              <SwitchWrapper>
                <Switch
                  checked={demoMode}
                  onChange={(e) => setDemoMode(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <div>Demo Mode</div>
              </SwitchWrapper>
              <Button variant="outlined" onClick={() => setShowDevModal(true)}>
                Developer Settings
              </Button>
            </SectionWrapper>
          )}
        </Wrapper>
      </div>
      {showDevModal && (
        <Modal
          title="Developer Settings"
          onClose={() => setShowDevModal(false)}
          open={showDevModal}
        >
          <div>
            <p>Axios BaseURL</p>
            <p>{axios.defaults.baseURL}</p>
            {userPosition && (
              <div>
                <p>User Position</p>
                <p>lat: {userPosition.coords.latitude}</p>
                <p>lng: {userPosition.coords.longitude}</p>
              </div>
            )}
          </div>
        </Modal>
      )}
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  // border: 1px solid #000;
  background-color: #fff;
  margin-top: 20px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 16px;
  height: 200px;
  margin: 0;
`;

const SectionTitle = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 20px;
  margin-bottom: 4px;
  font-weight: bold;
`;