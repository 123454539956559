const DQ_REASONS = [
  "LIMITED ROOF SPACE",
  "EXCESSIVE SHADING",
  "NO ROOF ACCESS",
  "ROOF PITCH",
  "SLATE ROOF",
  "CLAY ROOF",
  "RENTERS",
  "HAS SOLAR",
  "LOW USAGE",
  "BAD CREDIT",
  "OTHER",
];

export { DQ_REASONS };
