import React, { useContext, useState } from "react";
import { AuthContext } from "../../../Auth";

import OnboardModal from "./OnboardModal";
import ManagerModal from "./ManagerModal";

const CheckUserDataModal = () => {
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(
    !!(user?.onboard_modal || user?.manager_modal)
  );

  if (user?.onboard_modal)
    return <OnboardModal open={open} setOpen={setOpen} />;

  if (user?.manager_modal)
    return <ManagerModal open={open} setOpen={setOpen} />;

  return <div/>;
};

export default CheckUserDataModal;

