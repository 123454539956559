import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MutationCache } from "@tanstack/react-query";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { postNewLead } from "./api";

import { NotFound, PrivateRoute } from "./Auth";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { Dashboard, Map, NewLead, Settings, ViewLeads } from "./Views";
import { ContextProvider } from "./Context";
import { AuthProvider } from "./Auth";

import { setKey } from "react-geocode";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
    },
    mutations: {
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      networkMode: "online",
    },
  },

  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    onSuccess: (data) => {},
    onError: (error) => {},
  }),
});

// we need a default mutation function so that paused mutations can resume after a page reload
queryClient.setMutationDefaults(["post-lead"], {
  // mutationKey: ["post-lead"],
  mutationFn: postNewLead,
  networkMode: "online",
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  // Set the API key for Google Maps Geocoding API
  setKey("AIzaSyALq_pM-nFcf_1NotnMDnMWfTAmCHd1saw");


  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: asyncStoragePersister }}
      onSuccess={() => {
        queryClient.resumePausedMutations().then(() => {
          queryClient.invalidateQueries({
            queryKey: ["get-leads"],
            // react query is saying im online for some reason so this query data is being invalidated when it shouldnt be
            type: "inactive", // only invalidate inactive queries
            refetchType: "none", // don't refetch until needed
          });
        });
      }}
    >
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/map" element={<Map />} />
                  <Route path="/lead" element={<NewLead />} />
                  <Route path="/leads" element={<ViewLeads />} />
                  <Route path="/settings" element={<Settings />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ContextProvider>
    </PersistQueryClientProvider>
  );
}

export default App;
