import React from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";

export default function FormDateTimePicker({ name, required, rules, ...props }: any) {
  const { control } = useFormContext<FieldValues>();


  return (
    <Controller
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            {error && (
              <p style={{ color: "red" }}>Date is required</p>
            )}
            <MobileDateTimePicker
              required={required}
              onChange={onChange}
              error={error}
              {...props}
              {...field}
              //   value={field.value ?? ""}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        );
      }}
      name={name ?? ""}
      rules={{ ...rules, required }}
    />
  );
}
