import { useState } from "react";
import styled from "@emotion/styled";

import { ReactComponent as NetworkIcon } from "../../assets/icons/NetworkIcon.svg";
import { ReactComponent as NoNetworkIcon } from "../../assets/icons/NoNetworkIcon.svg";
import { COLORS } from "../../theme";
import { CSSProperties } from "react";
import { Collapse, Typography } from "@mui/material";
import { useNetworkStatus } from "../../Hooks";

const NetworkStatusOverlay = ({
  style = { top: 16, left: 16 },
}: {
  style?: CSSProperties;
}) => {
  let { isOnline } = useNetworkStatus();
  const [expanded, setExpanded] = useState(false);
  const color = isOnline ? COLORS.GREEN : COLORS.RED;

  return (
    //@ts-ignore
    <Wrapper
      style={{ backgroundColor: color, ...style }}
      onClick={(e) => {
        e.stopPropagation();
        setExpanded((prev) => !prev);
      }}
    >
      {isOnline ? ( //@ts-ignore
        <NetworkIcon style={{ color }} />
      ) : (
        //@ts-ignore
        <NoNetworkIcon style={{ color }} />
      )}

      <Collapse in={expanded} orientation="horizontal">
        {expanded && (
          <>
            <Typography
              style={{
                padding: "0px 12px",
                color: isOnline ? COLORS.DARKGREY : COLORS.WHITE,
                whiteSpace: "nowrap",
              }}
            >
              {isOnline ? "Connected to the network" : "Not connected:"}
            </Typography>
            {!isOnline && (
              <Typography
                style={{
                  padding: "0px 12px",
                  color: isOnline ? COLORS.DARKGREY : COLORS.WHITE,
                  fontSize: "12px",
                }}
              >
                Submitted Leads will automatically resume uploading when
                re-connected to the network.
              </Typography>
            )}
          </>
        )}
      </Collapse>
    </Wrapper>
  );
};

export default NetworkStatusOverlay;

const Wrapper = styled.button`
  position: absolute;
  border-radius: 100px;
  padding: 4px;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;
  border: none;
`;
