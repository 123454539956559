import { Modal } from "../../GlobalComponents";
import styled from "@emotion/styled";
import MarkerIconCyan from "../../assets/Map/marker-cyan.png";
import MarkerIconYellow from "../../assets/Map/marker-yellow.png";
import MarkerIconGreen from "../../assets/Map/marker-green.png";
import DQIcon from "../../assets/Map/DQIcon.svg";
import HasSolarIcon from "../../assets/Map/HasSolarIcon.svg";
import { COLORS } from "../../theme";

type LegendModalProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
}

export default function DQLeadsModal({
  open,
  setOpen,
}: LegendModalProps) {
  return (
    <Modal title="Map Markers" onClose={() => setOpen(false)} open={open} contentContainerStyle={{backgroundColor: COLORS.PRIMARY}}>
      <ContentWrapper>
        <MarkersWrapper>
            <MarkerRow>
            <img src={MarkerIconCyan} alt="Marker Icon"  width={40} height={60} />
            <MarkerText>Interactive</MarkerText>
            </MarkerRow>
            <MarkerRow>
            <img src={MarkerIconYellow} alt="Marker Icon" width={40} height={60} />
            <MarkerText>Non-Interactive</MarkerText>
            </MarkerRow>
            <MarkerRow>
            <img src={MarkerIconGreen} alt="Marker Icon" width={40} height={60} />
            <MarkerText>Install</MarkerText>
            </MarkerRow>
            <MarkerRow>
            <img src={DQIcon} alt="Marker Icon" width={40} height={60} />
            <MarkerText>DQ</MarkerText>
            </MarkerRow>
            <MarkerRow>
            <img src={HasSolarIcon} alt="Marker Icon" width={40} height={60} />
            <MarkerText>Has Solar</MarkerText>
            </MarkerRow>
        </MarkersWrapper>
      </ContentWrapper>
    </Modal>
  );
}

const ContentWrapper = styled.div`
    background-color: ${COLORS.PRIMARY};
`;

const MarkersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

const MarkerRow = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
`;

const MarkerText = styled.div`
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding-left: 30px;
`;