import React from "react";
import StateWidget from "./StateWidget";
import WeatherWidget from "./WeatherWidget";
import styled from "@emotion/styled";

export default function Header() {
  return (
    <Wrapper>
      <StateWidget />
      <WeatherWidget />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 84px;
  border-radius: 6px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

