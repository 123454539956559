import axios from "axios";

type GetDQLeadsParams = {
  location?: any;
  reasons: string[];
};

const getDQLeads = async ({ location, reasons }: GetDQLeadsParams) => {
  const testCoords = {
    latitude: 40.706325357461786,
    longitude: -73.60266990959644,
  };
  if (!reasons || reasons.length === 0) {
    return [];
  }

  if(!location) {
      throw new Error("Missing required parameters");
  }

 

  const reasonsString = reasons.join(",");

  // https://api-dev.momentumsolar.io/mrelay_v2/filters/dqs?latitude=40.706325357461786&longitude=-73.60266990959644&dq_reason=EXCESSIVE SHADING,NO ROOF ACCESS,HAS SOLAR

  const endpoint = `/mrelay_v2/filters/dqs?latitude=${testCoords.latitude}&longitude=${testCoords.longitude}&dq_reason=${reasonsString}`;

  const response = await axios.get(endpoint);


  return response.data;
};

export { 
    getDQLeads
}