import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
import { useForm, FormProvider } from "react-hook-form";
import { FormTextInput, FormSelect } from "../FormComponents";
import { TextField } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import PlaceIcon from "@mui/icons-material/Place";
import Modal from "../Modal";
import { useDebounce } from "use-debounce";
import { getGeocodedAddress } from "../../util";

const API_KEY =
  "https://maps.googleapis.com/maps/api/geocode/json?address='${initialValue}'&key=AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8";

interface AddressLookupModalProps {
  onAddressConfirm: (arg: AddressReturnObject) => void;
  backText?: string;
  open: boolean;
  onClose?: () => void;
}

export default function AddressLookupModal({
  onAddressConfirm,
  backText = "Back",
  open,
  onClose,
}: AddressLookupModalProps) {
  const [manual, setManual] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<AddressReturnObject | null>(null);
  const [enteredAddress, setEnteredAddress] = useState("");
  const [debouncedEnteredAddress] = useDebounce(enteredAddress, 500);
  const inputRef = React.createRef<HTMLInputElement>();
  const [isFocused, setIsFocused] = useState(false);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: API_KEY,
    });

  useEffect(() => {
    if (debouncedEnteredAddress && isFocused) {
      getPlacePredictions({ input: debouncedEnteredAddress });
    }
  }, [debouncedEnteredAddress]);

  const methods = useForm();

  const onAddressSelect = async (address: any) => {
    setEnteredAddress(address.description);
    getPlacePredictions({ input: "" });
    const geocodedAddress = await getGeocodedAddress(address.description);
    setSelectedAddress(geocodedAddress);
  };

  useEffect(() => {
  }, [inputRef]);

  const onSubmitManual = (data: any) => {
    const combinedAddress = {
      address: {
        formatted_address: `${data.address_line1}, ${data.city}, ${data.state}, ${data.zip}`,
        address_line1: data.address_line1,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
      },
      coords: null,
    };
    onAddressConfirm(combinedAddress);
  };

  const renderInputs = () => {
    if (manual) {
      return (
        <div
          style={{
            width: "100%",
            height: 325,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <FormTextInput
            required
            name="address_line1"
            label="Street"
            sx={{ width: "100%" }}
          />
          <FormTextInput
            required
            name="city"
            label="City"
            sx={{ width: "100%" }}
          />
          <FormSelect
            required
            name="state"
            label="State"
            options={STATES.map((state) => ({ value: state, label: state }))}
            sx={{ width: "100%" }}
          />
          <FormTextInput
            required
            name="zip_code"
            label="Zip"
            sx={{ width: "100%" }}
          />
        </div>
      );
    }

    return (
      <div style={{ width: "100%" }}>
        <p>Search for an Address</p>
        <TextField
          ref={inputRef}
          placeholder="Search Address"
          fullWidth
          variant="outlined"
          sx={{ width: "100%" }}
          onChange={(e) => {
            setEnteredAddress(e.target.value);
            getPlacePredictions({ input: e.target.value });
            setEnteredAddress(e.target.value);
            // getPlacePredictions({ input: e.target.value });
          }}
          value={enteredAddress}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {isPlacePredictionsLoading ? (
          <div>Loading...</div>
        ) : placePredictions.length > 0 ? (
          <Addresses
            addresses={placePredictions}
            onAddressClick={onAddressSelect}
          />
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ padding: 0 }}
      title="Lead Address"
    >
      <FormProvider {...methods}>
        <ModalBody>
          {renderInputs()}
          <ButtonWrapper>
            <SubmitButton
              active={false}
              onClick={() => {
                setSelectedAddress(null);
                setManual(!manual);
              }}
            >
              {manual ? "Search Address" : "Enter Manually"}
            </SubmitButton>
            <SubmitButton
              active={
                manual ? methods.formState.isValid : selectedAddress !== null
              }
              onClick={() => {
                if (manual) {
                  return methods.handleSubmit(onSubmitManual)();
                }
                return selectedAddress && onAddressConfirm(selectedAddress);
              }}
            >
              Use this Address
            </SubmitButton>
          </ButtonWrapper>
        </ModalBody>
      </FormProvider>
    </Modal>
  );
}

const Addresses = ({
  addresses,
  onAddressClick,
}: {
  addresses: any[];
  onAddressClick: (address: string) => void;
}) => {
  return (
    <AddressesWrapper>
      {addresses.map((address) => (
        <Address
          onClick={() => {
            onAddressClick(address);
          }}
        >
          {address.description}
        </Address>
      ))}
    </AddressesWrapper>
  );
};

const AddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.GREY2};
  overflow-y: auto;
`;

const Address = styled.div`
  margin: 5px 0;
  padding: 5px;
  border-bottom: 1px solid ${COLORS.GREY2};
  width: 90%;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 80%;
  height: "100%";
`;

type SubmitButtonProps = {
  active: boolean;
};

const SubmitButton = styled.button<SubmitButtonProps>`
  margin: 10px 0;
  background-color: ${(props) => (props.active ? COLORS.GREEN : COLORS.GREY2)};
  border: none;
  color: ${COLORS.GREY};
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
