import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Login from "../Login";
import { AuthContext } from "../AuthProvider";

function PrivateRoute() {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Outlet />;
  }

  return <Login />;
}

export default PrivateRoute;
