import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { InfoWindowF } from "@react-google-maps/api";

function LeadInfoWindow({
  lead,
  onClose = () => {},
  setSelectedLead = () => {},
}: {
  lead?: Lead;
  onClose?: Function;
  setSelectedLead?: Function;
}) {
  const closeInfoWindow = (e?: any) => {
    onClose();
  };

  if (!lead) return null;

  const position = {
    lat: lead.lead_latitude ?? lead.canvasser_latitude,
    lng: lead.lead_longitude ?? lead.canvasser_longitude,
  } as google.maps.LatLngLiteral;

  if (!position.lat || !position.lng) return null;

  const handleViewLead = () => {
    onClose();
    //@ts-ignore
    // navigate("/leads", { state: { lead } });
    setSelectedLead(lead);
  };

  return (
    <InfoWindowF
      position={position}
      options={{ pixelOffset: new google.maps.Size(0, -60) }}
      onCloseClick={closeInfoWindow}
    >
      <Wrapper>
        <Typography>{lead?.lead_type?.label}</Typography>
        {lead?.dq_reason && (
          <div>
            <Typography>DQ Reason:</Typography>
            <Typography>{lead?.dq_reason}</Typography>
          </div>
        )}
        <div>
          <Typography>{lead.address_line1}</Typography>
          <Typography>
            {lead.city} {lead.state}
            {lead.zip_code}
          </Typography>
        </div>
        {lead?.lead_subtype !== "DQ" && (
          <Button variant="outlined" onClick={() => handleViewLead()} fullWidth>
            View
          </Button>
        )}
      </Wrapper>
    </InfoWindowF>
  );
}

export default LeadInfoWindow;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;
