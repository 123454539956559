import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { getInstalls } from "../api";
import Context from "../Context";

function useGetInstallsQuery() {
  const { userPosition } = useContext(Context);
  const [center, setCenter] = useState(userPosition);

  const query = useQuery({
    queryKey: ["get-installs", center],
    queryFn: () =>
      center &&
      getInstalls({
        latitude: center?.coords?.latitude,
        longitude: center?.coords?.longitude,
      }),
    enabled: !!center,
  });

  useEffect(() => {
    if (!center) return setCenter(userPosition);
    const distance = getDistanceFromLatLonInKm(
      center.coords.latitude,
      center.coords.longitude,
      userPosition.coords.latitude,
      userPosition.coords.longitude
    );

    if (distance >= 1) {
      setCenter(userPosition);
    }
  }, [userPosition]);

  return query;
}

export default useGetInstallsQuery;

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  if (!lat1 || !lon1 || !lat2 || !lon2) return 0;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
