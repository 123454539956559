import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Login from "./Login";
import { useGetUser } from "./Hooks";
import { useQueryClient } from "@tanstack/react-query";
import { initAxiosDefaultValues } from "../util";
import Context from "../Context";
import { useMsal } from "@azure/msal-react";
import { Outlet, useLocation } from "react-router-dom";
import axios from "axios";

interface ContextProps {
  user: User | undefined;
  handleLogout: () => void;
  ENVOverride: "DEV" | "PROD";
  setENVOverride: (val: ContextProps["ENVOverride"]) => void;
  overrideAzureId?: string;
  setOverrideAzureId: Dispatch<SetStateAction<string | undefined>>;
  children: React.ReactNode;
}

const SUPPORT_USERS = [
  "dirwin@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "christopher.foy@momentumsolar.com",
  "derrick.huntley@momentumsolar.com",
];
export const AuthContext = React.createContext<ContextProps>({
  user: undefined,
  handleLogout: () => {},
  ENVOverride: "PROD",
  setENVOverride: () => {},
  setOverrideAzureId: () => {},
  children: null,
});

export default function AuthProvider({ children }: { children: any }) {
  const { instance, accounts, inProgress } = useMsal();
  const [user, setUser] = useState<User | undefined>();
  const [ENVOverride, _setENVOverride] =
    useState<ContextProps["ENVOverride"]>("PROD");
  //OverrideAzureId is for external testing for approval on app stores. Uses email test1234@ms.com and Solar1234
  const [overrideAzureId, setOverrideAzureId] = useState<string | undefined>();
  const { devMode, setDevMode, demoMode, setDemoMode } = useContext(Context);
  const queryClient = useQueryClient();
  
  const { oid } = accounts[0]?.idTokenClaims ?? {};
  const { data: userData } = useGetUser(
    overrideAzureId ?? oid
  );

  useEffect(() => {
    if(userData) {
      if(!devMode) setENVOverride("PROD");
      else setENVOverride("DEV");
    }
  }, [devMode]);

  useEffect(() => {
    //@ts-ignore
    const support = userData?.email && SUPPORT_USERS.includes(userData?.email);
    support && setDevMode(true);
    setENVOverride(support ? "DEV" : "PROD");
    userData &&
      setUser(() => {
        return {
          ...(userData as User),
          support,
        };
      });
  }, [userData]);

  const handleLogout = async () => {
    await instance.logoutRedirect();
    setDemoMode(false);
    setUser(undefined);
    clearQueries();
  };

  const clearQueries = () => {
    queryClient.removeQueries({ queryKey: ["get-azure-token"] });
    queryClient.resetQueries({ queryKey: ["get-azure-token"] });
    queryClient.resetQueries({ queryKey: ["get-user"] });
    queryClient.removeQueries({ queryKey: ["get-user"] });
  };

  const setENVOverride = (val: "DEV" | "PROD") => {
    _setENVOverride(val);
    initAxiosDefaultValues(val, user);
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogout,
        ENVOverride,
        setENVOverride,
        overrideAzureId,
        setOverrideAzureId,
        children,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
