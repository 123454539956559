import React from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { COLORS } from "~theme";
import { ButtonSelect, ButtonSelectProps } from "../BaseInputs/ButtonSelect";

interface FormSelectProps extends Omit<ButtonSelectProps, "onChange"> {
    name: string;
}

const FormButtonSelect = ({ name, ...rest }: FormSelectProps) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        return (
            <ButtonSelect
                {...rest}
                onChange={(value) => onChange(value)}
            />
        );
      }}
      name={name ?? ""}
    />
  );
};

export default FormButtonSelect;