import React, { useContext } from "react";
import styled from "@emotion/styled";
import { AuthContext } from "../../Auth";
import { abbrState } from "../../util";

export default function UserInfoWidget() {
  const { user } = useContext(AuthContext);
    console.log(user);
  if(!user) return (<div>Loading...</div>);

  return (
    <Wrapper>
      <ProfilePicWrapper>
        {/* <img src="https://via.placeholder.com/150" alt="Profile" /> */}
      </ProfilePicWrapper>
      <InfoWrapper>
        <Name>{ user.first_name + " " + user.last_name }</Name>
        <Text>{user?.user_type ?? ""}</Text>
        <Text>{abbrState(user?.office?.state, "name")?.toUpperCase() ?? ""}</Text>
      </InfoWrapper> 
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 84px;
  border-radius: 6px;
  background-color: #00bfef;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ProfilePicWrapper = styled.div`
    border-radius: 50%;
    background-color: lightgrey;
    width: 64px;
    height: 64px;
    // opacity: 1;
    margin-left: 32px;
    margin-right: 16px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
`;

const Text = styled.div`
    color: #fff;
    text-align: left;
`;

const Name = styled.div`
    color: #fff;
    text-align: left;
`;

