import styled from "@emotion/styled";
import { COLORS } from "../../../theme";

interface FormTextProps {
  variant?: "h1" | "h2" | "label" | "base" | "error";
  style?: React.CSSProperties;
  children: React.ReactNode;
}

function FormText({ variant = "base", style, children }: FormTextProps) {
  const TextComponent = FormTextComponents[variant];
  return <TextComponent style={style}>{children}</TextComponent>;
}

export default FormText;

const FormH1 = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${COLORS.PRIMARY};
  font-family: GothamBold;
`;

const FormH2 = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.PRIMARY};
  font-family: GothamBold;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: Gotham;
`;

const Base = styled.p`
  font-size: 14px;
  font-family: Lato;
`;

const Error = styled.p`
  font-size: 12px;
  font-family: Lato;
  color: ${COLORS.RED};
`;

const FormTextComponents = {
  h1: FormH1,
  h2: FormH2,
  label: Label,
  base: Base,
  error: Error,
};
