import React from "react";
import { DQ_REASONS } from "../../util/optionSets/dqOptionSets";
import { FormSearchSelect } from "../../GlobalComponents/FormComponents/HookForm";

export default function DQReasons() {
  return (
    <FormSearchSelect
      name="dq_reason"
      options={DQ_REASONS}
      placeholder="Select DQ Reason"
      required
    />
  )
}
