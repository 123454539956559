import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, PageWrapper } from "../../GlobalComponents";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

import { ReactComponent as Calendar } from "../../assets/List/CalendarBlue.svg";
import { ReactComponent as House } from "../../assets/List/HouseBlue.svg";
import { ReactComponent as Person } from "../../assets/List/PersonBlue.svg";
import { AuthContext } from "../../Auth";
import { useDebounce } from "use-debounce";
import { useInfiniteQuery } from "@tanstack/react-query";
import { FetchLeadsResponse, getLeads } from "../../api";
import { LEAD_TYPE_FILTER_OPTIONS } from "../../util/optionSets";
import { useInView } from "react-intersection-observer";

import dayjs from "dayjs";
import { COLORS } from "../../theme";
import { useLocation } from "react-router-dom";
import ViewLeadDialog from "./ViewLeadDialog";

export default function ViewLeads() {
  const leadsRef = useRef<any>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterTerm, setFilterTerm] = useState(
    LEAD_TYPE_FILTER_OPTIONS[0].value
  );
  const { state } = useLocation();
  const [selectedLead, setSelectedLead] = useState<Lead | undefined>(
    state?.lead
  );

  const { user } = useContext(AuthContext);
  const [debounceSearchTerm] = useDebounce(searchTerm, 800);
  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch, //@ts-ignore
  } = useInfiniteQuery({
    queryKey: ["get-leads", user?.email, filterTerm, debounceSearchTerm],
    queryFn: async ({ pageParam }) =>
      await getLeads({
        email: user?.email ?? "",
        filter: filterTerm,
        search: debounceSearchTerm,
        next: pageParam,
      }),
    enabled: !!user?.email,
    getNextPageParam: (lastPage: FetchLeadsResponse) =>
      lastPage?.next ?? undefined,
  });

  const { ref, inView } = useInView({});

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  const leads = useMemo(
    () => data?.pages?.map((page) => page.values).flat(),
    [data]
  );

  return (
    <PageWrapper>
      <Wrapper>
        <Card>
          <Content>
            <Row>
              <House />
              <Person />
              <Calendar />
            </Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "16px",
                flexWrap: "wrap",
                margin: "auto",
              }}
            >
              <TextField
                label="Search by Address"
                variant="standard"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
                sx={{ width: "300px", maxWidth: "100%" }}
              />
              <FormControl
                sx={{ width: "300px", maxWidth: "100%" }}
                variant="standard"
                size="small"
              >
                <InputLabel id="demo-simple-select-label">
                  Filter Leads
                </InputLabel>
                <Select
                  value={filterTerm}
                  label="Filter Leads"
                  onChange={(e) => setFilterTerm(e.target.value)}
                >
                  {LEAD_TYPE_FILTER_OPTIONS.map((opt) => {
                    return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            <LeadsWrapper ref={leadsRef}>
              {leads?.map((lead, i) => {
                const isLast = i === leads.length - 1;
                return (
                  <LeadRow
                    ref={isLast ? ref : null}
                    lead={lead}
                    setSelectedLead={setSelectedLead}
                  />
                );
              })}
              {(isFetching || isFetchingNextPage) && (
                <MessageText
                  sx={{
                    color: COLORS.GREY,
                  }}
                >
                  Search for leads...
                </MessageText>
              )}
              {!isFetching && !isFetchingNextPage && !leads?.length && (
                <MessageText
                  sx={{
                    color: COLORS.GREY,
                  }}
                >
                  No Leads Found
                </MessageText>
              )}
              {error && (
                <MessageText
                  sx={{
                    color: COLORS.RED,
                  }}
                >
                  {error?.message}
                </MessageText>
              )}
            </LeadsWrapper>
          </Content>
        </Card>
        <ViewLeadDialog
          lead={selectedLead}
          onClose={() => setSelectedLead(undefined)}
        />
      </Wrapper>
    </PageWrapper>
  );
}

const LeadRow = React.forwardRef(
  (
    { lead, setSelectedLead }: { lead: Lead; setSelectedLead: Function },
    ref: any
  ) => {
    const date = dayjs(lead.appointment_datetime).format("MMM D, YYYY h:mm A");
    const isInteractive = lead.lead_type.value.toUpperCase() === "INTERACTIVE";
    return (
      <Row className="lead" ref={ref} onClick={() => setSelectedLead(lead)}>
        <div>
          <Typography fontSize={"12px"}>{lead.address_line1}</Typography>
          <Typography fontSize={"12px"}>
            {lead.city} {lead.state}
          </Typography>
          <Typography fontSize={"12px"}>{lead.zip_code}</Typography>
        </div>
        <div>
          <Dot
            style={{
              borderColor: isInteractive
                ? COLORS.LIGHT_GREEN
                : COLORS.LIGHTYELLOW,
              background: isInteractive ? COLORS.GREEN : COLORS.YELLOW,
            }}
          />
        </div>
        <div>
          {date !== "Invalid Date" ? (
            <Typography>{date}</Typography>
          ) : (
            <Typography
              sx={{ color: date === "Invalid Date" ? COLORS.GREY : "inherit" }}
            >
              No Demo Date
            </Typography>
          )}
        </div>
      </Row>
    );
  }
);

const Wrapper = styled.div`
  padding: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LeadsWrapper = styled.div`
  margin: 0px -20px;
  min-height: 40vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 4px;
  padding: 4px 0px;

  &.lead > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 4px;
  }

  &.lead:nth-of-type(2n - 1) {
    background-color: ${COLORS.LIGHTGREY};
  }
`;

const MessageText = styled(Typography)`
  text-align: center;
  padding: 16px;
`;

const Dot = styled.div`
  border-radius: 100px;
  aspect-ratio: 1;
  min-height: 16px;
  min-width: 16px;
  height: 24px;
  border: solid 4px;
  box-shadow: 1px 1px 4px lightgrey;
`;
