import React from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const FormSelect = ({ name, label, options, required, ...rest }:any) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
        return (
          <div>
            <InputLabel id={name}>{label}</InputLabel>
            {error && <p style={{ color: "red", margin: 0 }}>{name} is required</p>}
            <Select
              labelId={name}
              id={name}
              value={value}
              onChange={(e: SelectChangeEvent) => onChange(e.target.value)}
              error={error}
              {...rest}
            >
              {options.map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      }}
      name={name ?? ""}
    />
  );
};

export default FormSelect;