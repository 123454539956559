import axios from 'axios';

const getDQReasons = async () => {
    const endpoint = "/mrelay_v2/options/dqs";

    const response = await axios.get(endpoint);

    return response.data;
};


export { getDQReasons };