import React from "react";
import { Modal as MUIModal, ModalProps as MUIModalProps } from "@mui/material";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";

interface ModalPropss extends MUIModalProps {
    backButtonText?: string;
    contentContainerStyle?: any;
}   

export default function Modal(props: ModalPropss) {

  const onBack = () => {
    if (props.onClose) {
      //@ts-ignore
      props.onClose();
    }
  };
  return (
    <MUIModal {...props}>
      <ModalContent style={{...props.contentContainerStyle}}>
        <ModalHeader>
          <BackButton onClick={onBack}>{props.backButtonText || "Close"}</BackButton>
          <HeaderText>{props.title}</HeaderText>
        </ModalHeader>
        {props.children}
      </ModalContent>
    </MUIModal>
  );
}


const ModalContent = styled.div`
  background-color: white;
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  // z-index: 1000;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  height: 80%;
  overflow-y: auto;
  padding-bottom: 20px;
  box-sizing: border-box;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ModalHeader = styled.div`
  background-color: ${COLORS.PRIMARY};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: "100%";
`;

const BackButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  left: 20px;
  color: #fff;
  font-weight: 600;
`;

const HeaderText = styled.h2`
  color: #fff;
  font-weight: 600;
`;