import React, { RefObject, useRef } from "react";

import MarkerIconCyan from "../../assets/Map/marker-cyan.png";
import MarkerIconYellow from "../../assets/Map/marker-yellow.png";
import MarkerIconGreen from "../../assets/Map/marker-green.png";
import DQIcon from "../../assets/Map/DQIcon.svg";
import HasSolarIcon from "../../assets/Map/HasSolarIcon.svg";

import { Marker } from "@react-google-maps/api";

export default function CustomMarker({
  lead,
  hasBeenUploaded = true,
  setSelectedMarker = () => {},
}: {
  lead: Lead;
  hasBeenUploaded?: boolean;
  setSelectedMarker?: Function;
}) {
  const ref = useRef<RefObject<Marker> | undefined>();
  const style = { opacity: hasBeenUploaded ? 1 : 0.8 };
  const position = {
    lat: lead.lead_latitude ?? lead.canvasser_latitude,
    lng: lead.lead_longitude ?? lead.canvasser_longitude,
  } as google.maps.LatLngLiteral;

  let icon = "";

  if (!position.lat || !position.lng) return null;

  switch (lead?.lead_type?.value) {
    case "INTERACTIVE":
      icon = MarkerIconCyan;
      break;
    case "NONINTERACTIVE":
    default:
      icon = MarkerIconYellow;
      break;
  }

  if(lead?.lead_subtype === "DQ") {
    if (lead?.dq_reason === "HAS SOLAR") {
      icon = HasSolarIcon;
    } else {
      icon = DQIcon;
    }
  }

  const getPixelPositionOffset = (width: number, height: number) => {
    return { x: -(40 / 2), y: -(60 / 2) };
  };

  return (
    <Marker
      //@ts-ignore
      ref={ref}
      style={style}
      position={position}
      icon={{ url: icon, scaledSize: new google.maps.Size(40, 60) }}
      onClick={(e) => {
        // e.stopPropagation();
        setSelectedMarker({ element: ref.current, lead });
      }}
    />
  );
}

export const InstallMaker = ({ install }: { install: Install }) => {
  const position = {
    lat: install.latitude,
    lng: install.longitude,
  } as google.maps.LatLngLiteral;
  return (
    <Marker
      //@ts-ignore
      position={position}
      icon={{ url: MarkerIconGreen, scaledSize: new google.maps.Size(40, 60) }}
    />
  );
};

// export const DQMarker = ({ dq }: { dq: DQ }) => {};