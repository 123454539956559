import axios from "axios";

export interface PatchUserInfoBody {
  first_name?: string;
  last_name?: string;
  office_name?: string;
  office_state?: string;
  team_leader_email?: string;
  team_leader_first_name?: string;
  team_leader_last_name?: string;
}

export const patchUserInfo = async (
  azure_id: string | undefined,
  body: PatchUserInfoBody
) => {
  console.log("azure id", azure_id, body)
  if (!azure_id) return null;
  return (await axios.patch("/mrelay_v2/user/" + azure_id, body)).data;
};

export interface Office {
  state: string;
  name: string;
}

export const getOfficeLocations = async () => {
  const res = await axios.get("/mrelay_v2/options");
  return res.data;
};
