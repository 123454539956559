export function parseLead(lead: FetchedLead | PostNewLeadBody) {
  const lead_type = lead.lead_type.toUpperCase();
  return {
    ...lead,
    lead_type:
      lead_type === "INTERACTIVE"
        ? { label: "Interactive", value: lead_type }
        : { label: "Non-Interactive", value: lead_type },
    appointment_datetime:
      lead.appointment_datetime === "1900-01-01T00:00:00.000Z"
        ? ""
        : lead.appointment_datetime,
  } as Lead;
}
