import React, { useEffect } from "react";

import { QueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import axios from "axios";

const useGetUser = (
  microsoft_id: string | undefined,
  options: QueryOptions = {}
): UseQueryResult => {
  const query = useQuery({
    ...options,
    queryKey: ["get-user", microsoft_id],
    queryFn: async () => {
      if (!microsoft_id) return null;
      const { data } = await axios.get(
        "https://api.momentumsolar.io/mrelay_v2/user/" + microsoft_id
      );
      return data;
    },
    enabled: !!microsoft_id,
  });

  useEffect(() => {
    if (query.error)
      alert(
        "There was an issue getting user info\n\n" +
          query.error +
          "\n\n" + //@ts-ignore
          query?.error?.response?.data?.message
      );
  }, [query.error]);

  return query;
};

export default useGetUser;
