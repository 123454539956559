import axios from "axios";

export function initAxiosDefaultValues(
  environment = "PROD",
  user: User | undefined
) {
  console.log(user);
  if (user) {
    // axios.defaults.headers.azure_id = user.azure_id;
  }
  axios.defaults.baseURL =
    environment === "DEV"
      ? "https://api-dev.momentumsolar.io"
      : "https://api.momentumsolar.io";
}
