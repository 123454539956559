import axios from "axios";

export interface PostNewLeadBody extends Omit<Lead, "lead_type"> {
  insert_crm_lead: boolean;
  lead_type: LeadType["value"];
}

const postNewLead = async (body: PostNewLeadBody) => {
  const response = await axios.post("/mrelay_v2/lead", body);
  return response?.data;
};

export default postNewLead;
