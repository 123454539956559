import React from "react";
import { BeatLoader } from "react-spinners";
import { COLORS } from "../theme";

export default function LoadingDots({text}: {text: string}) {
  return (
    <div>
      <BeatLoader color={COLORS.PRIMARY} />
      {text}
    </div>
  );
}
