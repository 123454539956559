import React, { useState, useEffect } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useWatchUserPosition } from "./Hooks";

const API_KEY =
  "https://maps.googleapis.com/maps/api/geocode/json?address='${initialValue}'&key=AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8";

type ContextProps = {
  demoMode: boolean;
  setDemoMode: (demoMode: boolean) => void;
  devMode: boolean;
  setDevMode: (devMode: boolean) => void;
  placesService: google.maps.places.PlacesService | null;
  placePredictions: google.maps.places.AutocompletePrediction[];
  getPlacePredictions: (input: any) => void;
  isPlacePredictionsLoading: boolean;
  userPosition: any;
  watchingPosition: boolean;
  startWatchingPosition: () => void;
};

const Context = React.createContext<ContextProps>({} as ContextProps);
type ContextProviderProps = {
  children: React.ReactNode;
};

export const ContextProvider = (props: ContextProviderProps) => {
  const [demoMode, setDemoMode] = useState(false);
  const [devMode, setDevMode] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: API_KEY,
  });
  const {
    position: userPosition,
    startWatchingPosition,
    watching: watchingPosition,
  } = useWatchUserPosition();

  useEffect(() => {
    startWatchingPosition();
  }, []);

  return (
    <Context.Provider
      value={{
        demoMode,
        setDemoMode,
        devMode,
        setDevMode,
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
        userPosition,
        watchingPosition,
        startWatchingPosition,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Context;
