import styled from "@emotion/styled";
import React from "react";

const Card = styled.div`
  padding: 20px;

  border-radius: 5px;
  background-color: white;
`;

export default Card;
