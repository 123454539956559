import { useState, useEffect } from "react";
import { Modal } from "../../../GlobalComponents";
import { Checkbox } from "@mui/material";
import styled from "@emotion/styled";
import { COLORS } from "../../../theme";

type DQLeadsModalProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  dqOptions: string[];
  onClose: (arg: Reasons) => void;
  selectedDQs: string[];
}

export default function DQLeadsModal({
  open,
  setOpen,
  dqOptions,
  onClose,
  selectedDQs,
}: DQLeadsModalProps) {
  const [selectedReasons, setSelectedReasons] = useState<any>({});

  useEffect(() => {
    if (selectedDQs.length === 0) {
      setSelectedReasons({});
    }
  }, [selectedDQs]);

  const onCloseHelper = () => {
    setOpen(false);
    onClose(selectedReasons);
  };
  return (
    <Modal title="DQ Leads Filter" onClose={onCloseHelper} open={open}>
      {!dqOptions?.length ? (
        <LoadingContainer>
          <Text>Loading...</Text>
        </LoadingContainer>
      ) : (
        <ScrollView>
          <Button
            style={{ width: "50%" }}
            onClick={() => {
              if (Object.keys(selectedReasons).length === dqOptions.length) {
                setSelectedReasons({});
                return;
              }
              const newSelectedReasons = dqOptions.reduce((acc, reason) => {
                //@ts-ignore
                acc[reason] = true;
                return acc;
              }, {});
              setSelectedReasons(newSelectedReasons);
            }}
          >
            Select All
          </Button>

          <DQsContainer>
            {dqOptions?.map((reason, index) => (
              <ReasonWrapper
                key={index}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  width: 150,
                }}
              >
                <StyledCheckbox
                  //   value={selectedReasons[reason]}
                  checked={selectedReasons[reason] ? true : false}
                  onChange={(e) =>
                    setSelectedReasons({
                      ...selectedReasons,
                      [reason]: e.target.checked,
                    })
                  }
                  style={{
                    transform: "scale(1.5)",
                  }}
                />
                <CheckboxText>{reason}</CheckboxText>
              </ReasonWrapper>
            ))}
          </DQsContainer>
          <ButtonsContainer>
            <Button
              style={{ backgroundColor: COLORS.GREY }}
              onClick={() => {
                setSelectedReasons({});
                onClose({});
                setOpen(false);
              }}
            >
              Clear
            </Button>
            <Button onClick={onCloseHelper}>Done</Button>
          </ButtonsContainer>
        </ScrollView>
      )}
    </Modal>
  );
}

const LoadingContainer = styled.div`
    height: 100px;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div``;

const CheckboxText = styled.div`
  display: flex;
  font-size: 14;
  flex-shrink: 1;
  flex-wrap: wrap;
  max-width: 85%;
`;

const ScrollView = styled.div`
  max-height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
`;

const Button = styled.button`
    background-color: ${COLORS.PRIMARY};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 50px;
`;

const ReasonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 150px;
    margin: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
    padding: 10,
    margin: 10,
    border-radius: 8,
`;

const DQsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10;
`;