import React, { useRef } from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
import NavBar from "./NavBar";

type PageWrapperProps = {
  children?: React.ReactNode;
  colors?: [string, string];
  variant?: "standard" | "gradient";
  footerBackground?: string;
};

export default function PageWrapper({
  children,
  colors = [COLORS.SECONDARY, COLORS.PRIMARY],
  variant = "gradient",
  footerBackground = COLORS.PRIMARY,
}: PageWrapperProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <Wrapper
      style={{
        background: footerBackground,
      }}
    >
      <Background
        style={{
          background:
            variant === "gradient"
              ? `linear-gradient(${colors[0]}, ${colors[1]})`
              : colors[1],
        }}
      >
        <Content>{children}</Content>
      </Background>
      <NavBar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  max-height: 100vh;
`;

const Content = styled.div`
  min-height: 100%;
  height: 100%;
  max-height: calc(100vh - 64px);
  width: min(1200px, 100%);
  margin: auto;
`;

const Background = styled.div`
  flex-grow: 1;
  min-height: 100%;
  min-width: 100%;
  overflow: auto;
`;
