import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import { ReactComponent as HomeIcon } from "../../assets/Nav/HomeIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/Nav/SettingsIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/Nav/LogoutIcon.svg";
import { ReactComponent as FooterLogo } from "../../assets/Nav/FooterLogo.svg";

import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const { instance } = useMsal();
  const location = useLocation();
  const { pathname } = location;

  return (
    <Wrapper>
      <Link to="/settings">
        <Button>
          <LinkContentWrapper>
            <SettingsIcon style={{ width: 40, height: 40 }} />
            <LinkText>Settings</LinkText>
          </LinkContentWrapper>
        </Button>
      </Link>
      <div
        style={{
          width: "40%",
          display: "flex",
          justifyContent: "center",
          paddingRight: 15,
        }}
      >
        {pathname === "/" ? (
          <FooterLogo />
        ) : (
          <Link to="/">
            <Button sx={{ flexDirection: "column" }}>
              <LinkContentWrapper>
                <HomeIcon style={{ width: 40, height: 40 }} />
                <LinkText>Home</LinkText>
              </LinkContentWrapper>
            </Button>
          </Link>
        )}
      </div>
      <Button
        sx={{ flexDirection: "column" }}
        onClick={() => instance.logout()}
      >
        <LinkContentWrapper>
          <LogoutIcon style={{ width: 40, height: 40 }} />
          <LinkText>Logout</LinkText>
        </LinkContentWrapper>
      </Button>
    </Wrapper>
  );
}

export default NavBar;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
  width: 100vw;
  max-width: 600px;

  margin: auto;
`;

const LinkText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
`;

const LinkContentWrapper = styled.div``;
