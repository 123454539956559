import React from "react";
import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";
import Switch from "@mui/material/Switch";

const FormSwitch = ({ name, label, ...rest }: any) => {
  const { control } = useFormContext<FieldValues>();

    return (
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div>
              <Switch
                checked={value}
                onChange={onChange}
                inputProps={{ "aria-label": label }}
                {...rest}
              />
            </div>
          );
        }}
        name={name ?? ""}
      />
    );
};

export default FormSwitch;
