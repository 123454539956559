import {
  geocode,
  RequestType,
} from "react-geocode";

// get lat and long from address
const getLatLong = async (address: string) => {
  const response = await geocode(RequestType.ADDRESS, address);
  console.log("GEOCODE RESPONSE", response)
  const { lat, lng } = response.results[0].geometry.location;

  return { lat, lng };
};

export default getLatLong;

const formatAddress = (address: any): AddressReturnObject => {
  console.log("geocoded address", address)
  const addressComponents = address.address_components;

  const formattedObject = {
    address: {
      formatted_address: address.formatted_address,
      address_line1: addressComponents.find((v : any) => v.types.includes("street_number"))?.long_name + " " + addressComponents.find((v : any) => v.types.includes("route"))?.long_name,
      city: addressComponents.find((v : any) => v.types.includes("locality"))?.long_name,
      state: addressComponents.find((v : any) => v.types.includes("administrative_area_level_1"))?.short_name,
      zip_code: addressComponents.find((v : any) => v.types.includes("postal_code"))?.long_name,
    },
    coords: {
      lat: address.geometry.location.lat,
      lng: address.geometry.location.lng,
    }
  }

  return formattedObject as AddressReturnObject;
};

const getGeocodedAddress = async (address: string) => {
  const response = await geocode(RequestType.ADDRESS, address).then((res) => {
    
    return formatAddress(res.results[0]);
  })

  return response;
};

export { getGeocodedAddress, formatAddress, getLatLong }