export const LEAD_TYPE_OPTIONS = [
  { label: "Interactive", value: "INTERACTIVE" },
  { label: "Non-Interactive", value: "NONINTERACTIVE" },
];

export const LEAD_TYPE_SUB_OPTIONS = {
  INTERACTIVE: [
    { label: "Interested", value: "INTERESTED" },
    { label: "Not Interested", value: "NOT INTERESTED" },
    { label: "DQ", value: "DQ" },
  ],
  NONINTERACTIVE: [
    { label: "Not Interested", value: "NOT INTERESTED" },
    { label: "Not Home", value: "NOT HOME" },
    { label: "DQ", value: "DQ" },
  ],
};

export const LEAD_TYPE_FILTER_OPTIONS = [
  { label: "All", value: "ALL" },
  ...LEAD_TYPE_OPTIONS,
];