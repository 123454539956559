import React from "react";
import Links from "./Links";
import Stats from "./Stats";
import styled from "@emotion/styled";
import { COLORS } from "../../theme";
import { PageWrapper } from "../../GlobalComponents";
import CheckUserDataModal from "./CheckUserDataModal";
import StatsWidget from "./StatsWidget";
import UserInfoWidget from "./UserInfoWidget";
import Header from "./Header";

export default function Dashboard() {
  return (
    <PageWrapper variant="gradient">
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Header />
        <Line />
        <Spacer />
        <UserInfoWidget />
        <StatsWidget />
        <Spacer />
        <HeaderText>Canvassing</HeaderText>
        <Line />
        <Links />
        <CheckUserDataModal />
      </div>
    </PageWrapper>
  );
}

const HeaderText = styled.p`
  color: ${COLORS.WHITE};

  font-size: 20px;
  margin-bottom: 4px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background-color: ${COLORS.WHITE};
`;

const Spacer = styled.div`
  margin-top: 32px;
`;

// import { useContext } from "react";
// import React, { useState } from "react";
// import Context from "~Context";
// import Header from "./Header";
// import Stats from "./Stats";
// import Links from "./Links";
// import TutorialOverlay from "./TutorialOverlay";
// import { LinearGradient } from "expo-linear-gradient";
// import { COLORS } from "~Theme";
// import styled from "@emotion/native";
// import { PageWrapper, NetworkStatusOverlay } from "~GlobalComponents";
// import { View } from "react-native";
// import CheckUserDataModal from "./CheckUserDataModal";

// export default function Dashboard({ route }: { route: any }) {
//   const { tutorialEnabled } = useContext(Context);

//   const [tutorialOpen, setTutorialOpen] = useState(tutorialEnabled);

//   return (
//     <PageWrapper variant="gradient">
//       <Header />
//       <Line />
//       <Spacer />
//       <Stats />
//       <Spacer />
//       <HeaderText>Canvassing</HeaderText>
//       <Line />
//       <Links />
//       <CheckUserDataModal />
//       {/* <TutorialOverlay open={true} setOpen={setTutorialOpen} /> */}
//     </PageWrapper>
//   );
// }

// const HeaderText = styled.Text`
//   color: ${COLORS.WHITE};

//   font-size: 20px;
//   margin-bottom: 4px;
// `;

// const Line = styled.View`
//   width: 100%;
//   height: 1px;
//   opacity: 0.5;
//   background-color: ${COLORS.WHITE};
// `;

// const Spacer = styled.View`
//   margin-top: 32px;
// `;

// const WrapperGradient = styled(LinearGradient)`
//   padding-horizontal: 24px;
//   height: 100%;
// `;
