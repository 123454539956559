import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { AuthContext } from "../../../Auth";
import {
  // Button,
  // EditButton,
  FormText,
  FormTextInput,
  // Input,
  LoadingDots,
  Modal,
  // ModalProps,
  // SelectDropdown,
  FormSelect,
} from "../../../GlobalComponents";
import styled from "@emotion/styled";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  getOfficeLocations,
  Office,
  patchUserInfo,
  PatchUserInfoBody,
} from "./api";
import { COLORS } from "../../../theme";
import { Button } from "@mui/material";
import { Form } from "react-router-dom";

type OnboardModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const OnboardModal = ({ open, setOpen }: OnboardModalProps) => {
  const { user, handleLogout } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [editingName, setEditingName] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const [formError, setFormError] = useState("");

  const methods = useForm();

  const { data } = useQuery({
    queryKey: ["get-office-locations"],
    queryFn: getOfficeLocations,
  });
  const offices = data?.locations;
  const states = useMemo(() => {
    if (!offices) return;
    return Array.from(new Set(offices.map((v: Office) => v.state))).sort();
  }, [offices]);

  const officeNames = useMemo(() => {
    if (!offices) return;
    const res = offices.reduce((result: string[], office: Office) => {
      const state = methods.getValues("office_state");
      if (!state || office.state === state) {
        result.push(office.name);
      }
      return result;
    }, []);
    return res;
  }, [offices, methods.watch("office_state")]);

  useEffect(() => {
    if (officeNames?.length === 1) methods.setValue("office_name", officeNames[0]);
  }, [officeNames]);

  const queryClient = useQueryClient();

  const {
    mutate: postUpdatedUserInfo,
    isPending: submitIsLoading,
    isSuccess: submitIsSuccess,
    error: submitError,
  } = useMutation({
    mutationFn: (body: any) => patchUserInfo(user?.azure_id, body),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["get-user"] }),
  });

  const submitChanges = () => {
    const body = {} as PatchUserInfoBody;
    if (methods.getValues("first_name"))
      body.first_name = methods.getValues("first_name");

    if (methods.getValues("last_name"))
      body.last_name = methods.getValues("last_name");

    if (methods.getValues("office_name"))
      body.office_name = methods.getValues("office_name");

    if (methods.getValues("office_state"))
      body.office_state = methods.getValues("office_state");

    if (
      !body.first_name ||
      !body.last_name ||
      !body.office_name ||
      !body.office_state
    )
      return setFormError("Some information is missing");

    postUpdatedUserInfo(body);
  };

  return (
    <Modal open={open} title={"Confirm your Information"}>
      <FormProvider {...methods}>
        <Wrapper>
          {step === 1 && (
            <>
              <FormText style={{ textAlign: "center" }}>
                If you are not a canvasser, this app should not be used to submit
                leads
              </FormText>
              <Button
                // color="navy"
                style={{ marginTop: 16 }}
                onClick={() => setStep(2)}
              >
                I am a canvasser
              </Button>
              <Button onClick={handleLogout}>Sign out</Button>
            </>
          )}
          {step === 2 &&
            (submitIsLoading ? (
              <LoadingDots text="Submitting..." />
            ) : submitIsSuccess ? (
              <>
                <FormText variant="h2" style={{ marginBottom: 24 }}>
                  Update Successful!
                </FormText>
                <Button onClick={() => setOpen && setOpen(false)}>Done</Button>
              </>
            ) : submitError ? (
              <>
                <FormText>Something went wrong</FormText>
                {/*@ts-ignore */}
                <FormText>{submitError?.message ?? submitError}</FormText>
                {/*@ts-ignore */}
                <FormText>{submitError?.response?.data?.message}</FormText>
              </>
            ) : (
              <>
                <FormText
                  variant="h2"
                  style={{ marginBottom: 16, textAlign: "center" }}
                >
                  Does everything look correct?
                </FormText>
                {editingName ? (
                  <>
                    <FormTextInput name={"first_name"} required />
                    <FormTextInput name={"last_name"} required />
                  </>
                ) : (
                  <Row>
                    <FormText variant="label">Name:</FormText>
                    <FormText>
                      {methods.watch("first_name")} {methods.watch("last_name")}
                    </FormText>
                    {!editingLocation && (
                      <Button onClick={() => setEditingName(true)}>Edit</Button>
                    )}
                  </Row>
                )}
                {editingLocation ? (
                  <>
                    <FormSelect
                      name="office_state"
                      options={states}
                      required
                    />
                    <FormSelect 
                      name="office_name"
                      options={officeNames}
                      required
                    />
                  </>
                ) : (
                  <Row>
                    <FormText variant="label">Office Location:</FormText>
                    <FormText>
                      {methods.watch("office_name")} - {methods.watch("office_state")}
                    </FormText>
                    {!editingName && (
                      <Button onClick={() => setEditingLocation(true)} >Edit</Button>
                    )}
                  </Row>
                )}

                {editingName ? (
                  <Button
                    style={{ marginTop: 16 }}
                    onClick={methods.handleSubmit(() => setEditingName(false))}
                  >Done Editing</Button>
                ) : editingLocation ? (
                  <Button
                    style={{ marginTop: 16 }}
                    onClick={methods.handleSubmit(() => setEditingLocation(false))}
                  >Done Editing</Button>
                ) : (
                  <>
                    {formError && (
                      <FormText
                        style={{ textAlign: "center", color: COLORS.RED }}
                      >
                        {formError}
                      </FormText>
                    )}
                    <Button
                      style={{ marginTop: 16 }}
                      onClick={submitChanges}
                    >My Info is Correct</Button>
                  </>
                )}
              </>
            ))}
        </Wrapper>
      </FormProvider>
    </Modal>
  );
};

export default OnboardModal;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
