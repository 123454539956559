import React from "react";
import { TextField, StandardTextFieldProps } from "@mui/material";
import { IMaskInput } from "react-imask";

export interface StyledTextInputProps extends StandardTextFieldProps {
  name?: string;
  label?: string;
  showLabel?: boolean;
  showErrors?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  error?: any;
  icon?: React.ReactNode;
  wrapperStyle?: React.CSSProperties;
  mask?: string;
  definitions?: object;
  maskType?: keyof typeof maskTypes;
  unmask?: boolean;
}

export const StyledTextInput = ({ ...props }: StyledTextInputProps) => {
  props.placeholder = props.placeholder ?? props.label ?? props.name;
  if (props.required) props.placeholder += "*";

  return (
    <div style={props.wrapperStyle}>
      <TextField
        {...props}
        InputProps={{
          inputComponent: props.mask ? TextMaskCustom as any : null,
        }}
        inputProps={{
          mask: props.mask,
          definitions: props.definitions,
          maskType: props.maskType ?? undefined,
          unmask: props.unmask,
        }}
      />
    </div>
  );
};

export default StyledTextInput;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  definitions: object;
  maskType?: keyof typeof maskTypes;
  unmask: boolean;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const {
      onChange,
      mask,
      definitions,
      maskType,
      unmask = true,
      ...other
    } = props;

    // mask: allows for custom mask to be passed in
    // definitions: allows for custom definitions to be passed in
    // maskType: allows for a predefined mask to be used
    // unmask(true by default): allows for the value to be unmasked before being passed to the onChange function
    return (
      <IMaskInput
        {...other}
        mask={maskType ? maskTypes[maskType].mask : mask}
        definitions={
          maskType ? maskTypes[maskType].definitions : { ...definitions }
        }
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
        unmask={unmask}
      />
    );
  }
);

const maskTypes = {
  phoneNumber: {
    mask: "+1 (000) 000-0000",
    definitions: {
      "#": /[0-9]/,
    },
  },
  currency: {
    mask: "$00.00",
    definitions: {
      num: {
        mask: Number,
        scale: 2,
        signed: true,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ".",
        mapToRadix: [","],
      },
    },
  },
};
