import React, { useContext } from "react";
import styled from "@emotion/styled";
import { AuthContext } from "../../Auth";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function StatsWidget() {
  const { user } = useContext(AuthContext);

  const { data } = useQuery({
    queryKey: ["get-knocks", user],
    queryFn: async () => {
      return (await axios.get("/mrelay_v2/analytics/knocks/" + user?.azure_id))
        ?.data;
    },
    enabled: !!user?.azure_id,
  });

  return (
    <Wrapper>
      <Title>Knocks</Title>
      <StatsWrapper>
        <StatColumn>
          <Text>Day</Text>
          <Value>{data?.day ?? "0"}</Value>
        </StatColumn>
        <Separator />
        <StatColumn>
          <Text>Week</Text>
          <Value>{data?.week ?? "0"}</Value>
        </StatColumn>
        <Separator />
        <StatColumn>
          <Text>Month</Text>
          <Value>{data?.month ?? "0"}</Value>
        </StatColumn>
      </StatsWrapper>
    </Wrapper>
  );
}


const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 84px;
  border-radius: 6px;
  background-color: #00bfef;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StatsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // border: 1px solid #fff;
`;

const StatColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;


const Title = styled.div`
    color: #fff;
`;

const Text = styled.div`
  color: #fff;
`;

const Value = styled.div`
  color: #fff;
  text-align: center;
`;

const Separator = styled.div`
    width: 1px;
    height: 30px;
    background-color: #fff;
    margin: 0 8px;
`;