import {
  useFormContext,
  Controller,
  FieldValues,
  ControllerProps,
} from "react-hook-form";
import { StyledTextInput } from "../BaseInputs";
import { TextField, StandardTextFieldProps } from "@mui/material";

// export interface FormTextInputProps extends Omit<StyledTextInputProps, "error"> {
//   rules?: ControllerProps["rules"];
// }

const FormTextInput = ({
  name,
  required,
  rules,
  mask,
  definitions,
  ...props
}: any) => {
  const { control } = useFormContext<FieldValues>();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <StyledTextInput
            required={required}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            {...props}
            {...field}
            mask={mask}
            definitions={definitions}
            value={field.value ?? ""}
          />
        );
      }}
      name={name}
      rules={{
        required: required ? "This field is required" : false,
        ...rules,
      }}
    />
  );
};

export default FormTextInput;
